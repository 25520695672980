import { Usuario } from './usuario';
import * as moment from 'moment';
import { Area } from './area';
import { Empresa } from './empresa';
import { Moneda } from './moneda';
import { InformacionCliente } from './informacionCliente';
import { ConfiguracionEmpresa } from './configuracionEmpresa';
import { ImpuestoEmpresa } from './impuestoEmpresa';
import { FormularioCliente } from './formularioCliente';
import { APIUrls } from '../api/apiUrls';
import { ContactoUsuario } from './contactoUsuario';
import { Agrupador } from './agrupador';
import { DocumentoUsuario } from './documentoUsuario';
import { ContactoAseguradora } from './contactoAseguradora';
import { Aseguradora } from './aseguradora';
import { AreaRamo } from './areaRamo';
import { Ramo } from './ramo';
import { Cobertura } from './cobertura';
import { Inciso } from './inciso';
import { Deducible } from './deducible';
import { ComisionAseguradoraProducto } from './comisionAseguradoraProducto';
import { Producto } from './producto';
import { CampoTipoCertificado } from './campoTipoCertificado';
import { TipoCertificado } from './tipoCertificado';
import { Certificado } from './certificado';
import { Poliza } from './poliza';
import { DocumentoPoliza } from './documentoPoliza';
import { PorcentajeCoaseguros } from './porcentajeCoaseguros';
import { PagadorCertificado } from './pagadorCertificado';
import { PagadorPoliza } from './pagadorPoliza';
import { IncisoCertificado } from './incisoCertificado';
import { DeducibleCertificado } from './deducibleCertificado';
import { ValorCertificado } from './valorCertificado';
import { CambioEstadoPoliza } from './cambioEstadoPoliza';
import { Declaracion } from './declaracion';
import { BeneficiarioDependienteCertificado } from './beneficiarioDependienteCertificado';
import { GastoProducto } from './gastoProducto';
import { DocumentoCobro } from './documentoCobro';
import { DocumentoCredito } from './documentoCredito';
import { SeguimientoCobro } from './seguimientoCobro';
moment.locale('es');
import * as momentTimezone from 'moment-timezone';
import { PlanillaCobro } from './planillaCobro';
import { GastoProductoDocumentoCobro } from './gastoProductoDocumentoCobro';
import { ImpuestoDocumentoCobro } from './impuestoDocumentoCobro';
import { AplicacionDocumentoCredito } from './aplicacionDocumentoCredito';
import { RazonPerdida } from './razonPerdida';
import { Prospecto } from './prospecto';
import { Cobrador } from './cobrador';
import { RevisionPoliza } from './revisionPoliza';
import { CorreccionControlCalidad } from './correccionControlCalidad';
import { PlantillaMapaProceso } from './plantillaMapaProceso';
import { PlantillaPasoMapaProceso } from './plantillaPasoMapaProceso';
import { PasoMapaProceso } from './pasoMapaProceso';
import { MapaProceso } from './mapaProceso';
import { Mensajeria } from './mensajeria';
import { SeguimientoReclamo } from './seguimientoReclamo';
import { Reclamo } from './reclamo';
import { FacturaReclamo } from './facturaReclamo';
import { RazonRamo } from './razonRamo';
import { CoberturaIncisoCertificado } from './coberturaIncisoCertificado';
import { TipoSiniestro } from './tipoSiniestro';
import { CoberturaCertificadoReclamo } from './coberturaCertificadoReclamo';
import { CertificadoReclamo } from './certificadoReclamo';
import { SolicitudPoliza } from './solicitudPoliza';
import { CertificadoSolicitud } from './certificadoSolicitud';
import { Notificacion } from './notificacion';
import { GastoProductoDocumentoCredito } from './gastoProductoDocumentoCredito';
import { ImpuestoDocumentoCredito } from './impuestoDocumentoCredito';
import { CambioEstadoReclamo } from './cambioEstadoReclamo';
import { CambioEstadoMensajeria } from './cambioEstadoMensajeria';
import { DocumentoMensajeria } from './documentoMensajeria';
import { DocumentoReclamo } from './documentoReclamo';
import { PlantillaRamo } from './plantillaRamo';
import { IncisoPlantillaRamo } from './incisoPlantillaRamo';
import { DeducibleIncisoPlantillaRamo } from './deducibleIncisoPlantillaRamo';
import { CoberturaIncisoPlantillaRamo } from './coberturaIncisoPlantillaRamo';
import { PlantillaSolicitud } from './plantillaSolicitud';
import { PermisoAccesoCliente } from './permisoAccesoCliente';
import { SolicitudCliente } from './solicitudCliente';
import { SeguimientoProspecto } from './seguimientoProspecto';
import { TipoReclamo } from './tipoReclamo';
import { CampoTipoReclamo } from './campoTipoReclamo';
import { ValorReclamo } from './valorReclamo';
import { DocumentoEnvioReclamo } from './documentoEnvioReclamo';
import { MiAnalytic } from './miAnalytic';
import { TarjetaCliente } from './tarjetaCliente';
import { DeducibleMonedaCertificado } from './deducibleMonedaCertificado';
import { PlantillaGestionCobro } from './plantillaMapaProceso copy';
import { GestionCobro } from './gestionCobro';
import { ComisionPolizaInciso } from './comisionPolizaInciso';
import { AclaracionPoliza } from './aclaracionPoliza';
import { DetalleDeclaracion } from './detalleDeclaracion';
import { ClausulaGarantiaPoliza } from './clausulaGarantiaPoliza';
import { EnvioSolicitud } from './envioSolicitud';
import { Ocupacion } from './ocupacion';
import { PlanillaLiquidacion } from './planillaLiquidacion';
import { BitacoraComisionInciso } from './bitacoraComisionInciso';
import { Pais } from './pais';
import { Departamento } from './departamento';
import { Municipio } from './municipio';
import { Zona } from './zona';
import { Banco } from './Banco';
import { TipoVehiculo } from './TipoVehiculo';
import { MarcaVehiculo } from './marcaVehiculo';
import { LineaVehiculo } from './lineaVehiculo';
import { DireccionCliente } from './direccionCliente';
import { DireccionCertificado } from './direccionCertificado';
import { BitacoraClausulaGarantia } from './BitacoraClausulaGarantia';
import { CambioEstadoCertificado } from './cambioEstadoCertificado';
import { IncisoHistoricoCertificado } from './incisoHistoricoCertificado';
import { DocumentoEnvioGestionCobro } from './documentoEnvioGestionCobro';
import { BitacoraCambioRiesgoCliente } from './BitacoraCambioRiesgoCliente';
import { CambioEstadoDireccionCliente } from './cambioEstadoDireccionCliente';
import { OperacionAcceso } from './operacionAcceso';

export class JSONConverters {
    public apiURLs = new APIUrls();

    public usuarioDeJSON = (json: any): Usuario => {
        if(!json) return null;
        var registro: Usuario;
        registro = new Usuario(
            json.id,
            json.codigo,
            json.nombre,
            json.apellido,
            json.nombreEmpresa,
            json.puesto,
            json.correo1,
            json.correo2,
            json.telefono1,
            json.telefono2,
            json.direccion,
            json.activo,
            json.isDeleted,
            json.esCliente,
            json.esAdministradorAgrupador,
            json.esAccesoACliente,
            json.esVendedor,
            json.esEjecutivo,
            json.esAgente,
            json.suspendido,
            json.fechaNacimiento,
            json.ultimaVisualizacion,
            json.AgrupadorId,
            json.InformacionClienteId,
            json.imagen,
            json.portada,
            json.EmpresaId,
        );
        registro.autoexpedible = json.autoexpedible;
        registro.correoCopiaSolicitudes = json.correoCopiaSolicitudes;

        if(json.fechaNacimiento) {
            registro.fechaNacimiento = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaNacimientoFormato = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaNacimientoDate = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'date');
        }

        if(json.ultimaVisualizacion) {
            registro.ultimaVisualizacion = this.convertirFecha(json.ultimaVisualizacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.ultimaVisualizacionFormato = this.convertirFecha(json.ultimaVisualizacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
        }

        if(json.AreaUsuarioEmpresas && json.AreaUsuarioEmpresas.length > 0) {
            var areaUsuarioEmpresaJSON = json.AreaUsuarioEmpresas[0];
            registro.AreaId = areaUsuarioEmpresaJSON.AreaId;
            if(areaUsuarioEmpresaJSON.Area) {
                registro.area = this.areaDeJSON(areaUsuarioEmpresaJSON.Area);
                registro.nombreArea = registro.area.nombre;
            }
            if(areaUsuarioEmpresaJSON.Empresa) registro.empresa = this.empresaDeJSON(areaUsuarioEmpresaJSON.Empresa);
            // Datos de vendedor
            registro.porcentajeComision = areaUsuarioEmpresaJSON.porcentajeComision;
            registro.porcentajeComision2 = areaUsuarioEmpresaJSON.porcentajeComision2;
            registro.metaMensual = areaUsuarioEmpresaJSON.metaMensual;
            registro.metaTrimestral = areaUsuarioEmpresaJSON.metaTrimestral;
            registro.metaSemestral = areaUsuarioEmpresaJSON.metaSemestral;
            registro.metaAnual = areaUsuarioEmpresaJSON.metaAnual;
            if(areaUsuarioEmpresaJSON.idsAgentesCartera) registro.idsAgentesCarteraArray = areaUsuarioEmpresaJSON.idsAgentesCartera.split(',').map((e) => parseInt(e));
            else registro.idsAgentesCarteraArray = [];
            if(areaUsuarioEmpresaJSON.idsVendedoresCartera) registro.idsVendedoresCarteraArray = areaUsuarioEmpresaJSON.idsVendedoresCartera.split(',').map((e) => parseInt(e));
            else registro.idsVendedoresCarteraArray = [];
        }

        if(json.empresas) {
            registro.empresas = [];
            for (let i = 0; i < json.empresas.length; i++) {
                const element = json.empresas[i];
                registro.empresas.push(this.empresaDeJSON(element));
            }
        }

        if(json.InformacionCliente) {
            var informacionCliente = this.informacionClienteDeJSON(json.InformacionCliente);
            registro.informacionCliente = informacionCliente;
            registro.nitCliente = registro.informacionCliente.nit;
        }

        if(json.Agrupador) {
            var agrupador = this.agrupadorDeJSON(json.Agrupador);
            registro.agrupador = agrupador;
            registro.nombreAgrupador = agrupador.nombre;
        }

        if(json.DocumentoUsuarios) {
            registro.documentos = [];
            for (let i = 0; i < json.DocumentoUsuarios.length; i++) {
                const element = json.DocumentoUsuarios[i];
                var documento = this.documentoUsuarioDeJSON(element);
                registro.documentos.push(documento);
            }
        }

        if(json.DireccionClientes) {
            registro.direcciones = [];
            for (let i = 0; i < json.DireccionClientes.length; i++) {
                const element = json.DireccionClientes[i];
                var direccion = this.direccionClienteDeJSON(element);
                registro.direcciones.push(direccion);
            }
        }

        if(json.TarjetaClientes) {
            registro.tarjetas = [];
            for (let i = 0; i < json.TarjetaClientes.length; i++) {
                const element = json.TarjetaClientes[i];
                var tarjeta = this.tarjetaClienteDeJSON(element);
                registro.tarjetas.push(tarjeta);
            }
        }

        if(json.UsuarioClientes) {
            registro.agentes = [];
            registro.ejecutivos = [];
            registro.agentesIds = [];
            registro.ejecutivosIds = [];
            for(var i = 0; i < json.UsuarioClientes.length; i++) {
                var element = json.UsuarioClientes[i];
                var usuarioCliente = null;
                if(element.Usuario) {
                    usuarioCliente = this.usuarioDeJSON(element.Usuario);
                }

                if(element.tipo == 'agente') {
                    if(registro.agentesIds.indexOf(element.UsuarioId) == -1) {
                        registro.agentesIds.push(element.UsuarioId);
                        if(usuarioCliente) registro.agentes.push(usuarioCliente);
                    }
                }
                else if(element.tipo == 'ejecutivo') {
                    if(registro.ejecutivosIds.indexOf(element.UsuarioId) == -1) {
                        registro.ejecutivosIds.push(element.UsuarioId);
                        if(usuarioCliente) registro.ejecutivos.push(usuarioCliente);
                    }
                }
            }
        }

        // Contactos de cliente
        if(json.ContactoUsuarios) {
            registro.contactos = [];
            for (let i = 0; i < json.ContactoUsuarios.length; i++) {
                const element = json.ContactoUsuarios[i];
                var contacto = this.contactoUsuarioDeJSON(element);
                registro.contactos.push(contacto);
            }
        }

        registro.meta = {};
        var nombres = '', apellidos = '';
        if(registro.nombre) nombres = registro.nombre;
        if(registro.apellido) apellidos = registro.apellido;
        registro.nombreCompleto = nombres + ' ' + apellidos;
        registro.nombreCompleto = registro.nombreCompleto.trim();

        registro.nombreCompletoConId = registro.nombreCompleto + ' (ID: ' + registro.id + ')';

        return registro;
    }

    public aseguradoraDeJSON = (json: any): Aseguradora => {
        if(!json) return null;
        var registro = new Aseguradora(
            json.id,
            json.nombre,
            json.codigo,
            json.razonSocial,
            json.direccion,
            json.telefono,
            json.correo,
            json.sitioWeb,
            json.telefonoHoraHabil,
            json.telefonoHoraInhabil,
            json.codigoAgente,
            json.edadLimiteDependientesAsegurados,
            json.diaCorteReporteAltasBajasColectivosPersonas,
            json.diaCorteEfectivoAltasBajasColectivosPersonas,
            json.valorComisionCobro,
            json.porcentajeComisionCobro,
            json.baseValorComisionCobro,
            json.basePorcentajeComisionCobro,
            json.limiteComisionCobro,
            json.imagen,
            json.portada,
            json.redHospitalariaLink,
            json.activo,
            json.liquidarConFraccionamiento,
            json.EmpresaId,
        );

        // Contactos de aseguradora
        if(json.ContactoAseguradoras) {
            registro.contactos = [];
            for (let i = 0; i < json.ContactoAseguradoras.length; i++) {
                const element = json.ContactoAseguradoras[i];
                var contacto = this.contactoAseguradoraDeJSON(element);
                registro.contactos.push(contacto);
            }
        }

        if(json.RamoAseguradoras) {
            registro.ramos = [];
            registro.idsRamos = [];
            for (let i = 0; i < json.RamoAseguradoras.length; i++) {
                const element = json.RamoAseguradoras[i];
                if(element.Ramo) {
                    var ramo = this.ramoDeJSON(element.Ramo);
                    registro.ramos.push(ramo);
                    registro.idsRamos.push(element.RamoId);
                }
            }
        }

        return registro;
    }

    public contactoUsuarioDeJSON = (json: any): ContactoUsuario => {
        if(!json) return null;
        var registro = new ContactoUsuario(
            json.id,
            json.nombre,
            json.puesto,
            json.telefono1,
            json.telefono2,
            json.correo1,
            json.correo2,
            json.direccionCobro,
            json.direccionCorrespondencia,
            json.observaciones,
            json.UsuarioId,
            json.EmpresaId,
        );

        registro.meta = {};
        var nombres = '', apellidos = '';
        if(registro.nombre) nombres = registro.nombre;
        registro.nombreCompleto = nombres + ' ' + apellidos;
        registro.nombreCompleto = registro.nombreCompleto.trim();

        return registro;
    }

    public cobradorDeJSON = (json: any): Cobrador => {
        if(!json) return null;
        var registro = new Cobrador(
            json.id,
            json.codigo,
            json.nombre,
            json.apellido,
            json.puesto,
            json.correo1,
            json.correo2,
            json.telefono1,
            json.telefono2,
            json.direccion,
            json.activo,
            json.isDeleted,
            json.EmpresaId,
        );

        registro.meta = {};
        var nombres = '', apellidos = '';
        if(registro.nombre) nombres = registro.nombre;
        if(registro.apellido) apellidos = registro.apellido;
        registro.nombreCompleto = nombres + ' ' + apellidos;
        registro.nombreCompleto = registro.nombreCompleto.trim();

        return registro;
    }

    public contactoAseguradoraDeJSON = (json: any): ContactoAseguradora => {
        if(!json) return null;
        var registro = new ContactoAseguradora(
            json.id,
            json.nombre,
            json.apellido,
            json.puesto,
            json.correo1,
            json.correo2,
            json.telefono1,
            json.telefono2,
            json.direccion,
            json.observaciones,
            json.AseguradoraId,
            json.AreaId,
        );

        registro.meta = {};
        var nombres = '', apellidos = '';
        if(registro.nombre) nombres = registro.nombre;
        if(registro.apellido) apellidos = registro.apellido;
        registro.nombreCompleto = nombres + ' ' + apellidos;
        registro.nombreCompleto = registro.nombreCompleto.trim();

        return registro;
    }

    public agrupadorDeJSON = (json: any): Agrupador => {
        if(!json) return null;
        var registro = new Agrupador(
            json.id,
            json.nombre,
            json.descripcion,
            json.linea,
            json.EmpresaId,
        );
        registro.inactivo = json.inactivo;

        if(json.linea) {
            var index = this.indexOf(this.apiURLs.lineasAgrupadores, 'id', json.linea);
            if(index != -1) registro.nombreLinea = this.apiURLs.lineasAgrupadores[index].nombre;
        }

        if(json.PermisoAccesoClientes) {
            registro.permisosAccesoClientes = [];
            for (let element of json.PermisoAccesoClientes) {
                let permiso = this.permisoAccesoClienteDeJSON(element);
                registro.permisosAccesoClientes.push(permiso);
            }
        }

        return registro;
    }

    public permisoAccesoClienteDeJSON = (json: any): PermisoAccesoCliente => {
        if(!json) return null;
        var registro = new PermisoAccesoCliente(
            json.id,
            json.correo,
            json.tipo,
            json.ClienteId,
            json.PolizaId,
            json.CertificadoId,
            json.AgrupadorId,
            json.EmpresaId,
        );

        if(json.tipo) {
            var index = this.indexOf(this.apiURLs.tiposPermisosAccesoClientes, 'id', json.tipo);
            if(index != -1) registro.nombreTipo = this.apiURLs.tiposPermisosAccesoClientes[index].nombre;
        }

        if(json.Poliza) {
            registro.poliza = this.polizaDeJSON(json.Poliza);
            registro.nombrePoliza = registro.poliza.nombrePoliza;
            registro.polizasCliente = [ registro.poliza ];
        }

        if(json.Certificado) {
            var certificado = this.certificadoDeJSON(json.Certificado);
            registro.certificado = certificado;
            registro.nombreCertificado = certificado.nombreCertificado;
            registro.certificadosPoliza = [ registro.certificado ];
        }

        if(json.Cliente) {
            var cliente = this.usuarioDeJSON(json.Cliente);
            registro.cliente = cliente;
            registro.nombreCliente = cliente.nombreCompleto;
        }

        return registro;
    }

    public coberturaDeJSON = (json: any): Cobertura => {
        if(!json) return null;
        var registro = new Cobertura(
            json.id,
            json.codigo,
            json.nombre,
            json.codigoAnterior,
            json.condicionEspecial,
            json.activo,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.Ramo) {
            var ramo = this.ramoDeJSON(json.Ramo);
            registro.ramo = ramo;
        }

        return registro;
    }

    public coberturaIncisoCertificadoDeJSON = (json: any): CoberturaIncisoCertificado => {
        if(!json) return null;

        var registro = new CoberturaIncisoCertificado(
            json.id,
            json.IncisoCertificadoId,
            json.CoberturaId,
        );

        if(json.Cobertura) {
            var cobertura = this.coberturaDeJSON(json.Cobertura);
            registro.cobertura = cobertura;
            registro.nombreCobertura = cobertura.nombre;
        }

        return registro;
    }

    public incisoDeJSON = (json: any): Inciso => {
        if(!json) return null;
        var registro = new Inciso(
            json.id,
            json.nombre,
            json.declarativo,
            json.acumulaSuma,
            json.descripcion,
            json.activo,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.Ramo) {
            var ramo = this.ramoDeJSON(json.Ramo);
            registro.ramo = ramo;
        }

        if(json.CoberturaIncisos) {
            registro.coberturas = [];
            registro.idsCoberturas = [];
            for (let i = 0; i < json.CoberturaIncisos.length; i++) {
                const element = json.CoberturaIncisos[i];
                if(element.Cobertura) {
                    var cobertura = this.coberturaDeJSON(element.Cobertura);
                    registro.coberturas.push(cobertura);
                    registro.idsCoberturas.push(cobertura.id);
                }
            }
        }

        if(json.Deducibles) {
            registro.deducibles = [];
            registro.idsDeducibles = [];
            for (let i = 0; i < json.Deducibles.length; i++) {
                const element = json.Deducibles[i];
                var deducible = this.deducibleDeJSON(element);
                registro.deducibles.push(deducible);
                registro.idsDeducibles.push(deducible.id);
            }
        }

        return registro;
    }

    public incisoCertificadoDeJSON = (json: any): IncisoCertificado => {
        if(!json) return null;
        var registro = new IncisoCertificado(
            json.id,
            json.nombre,
            json.declarativo,
            json.acumulaSuma,
            json.descripcion,
            json.sumaAsegurada,
            json.primaFacturada,
            json.primaSinDependientes,
            json.primaCon1Dependiente,
            json.primaConMasDependientes,
            json.primaAnualizada,
            json.primaEnDeposito,
            json.tipoCambio,
            json.porcentajeTasa,
            json.porcentajePrimaDeposito,
            json.tarifa,
            json.tipoTasa,
            json.estado,
            json.fechaVigenciaInicio,
            json.fechaVigenciaFin,
            json.fechaAlta,
            json.fechaBaja,
            json.fechaReporteAlta,
            json.fechaReporteBaja,
            json.CertificadoId,
            json.EmpresaId,
            json.RazonPerdidaId,
        );

        if(!registro.primaSinDependientes && registro.primaFacturada) registro.primaSinDependientes = registro.primaFacturada;

        if(registro.estado) {
            var index = this.indexOf(this.apiURLs.estadosIncisosCertificados, 'id', registro.estado);
            if(index != -1) registro.nombreEstado = this.apiURLs.estadosIncisosCertificados[index].nombre;
        }

        if(json.fechaVigenciaInicio) {
            registro.fechaVigenciaInicio = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaInicioFormato = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaInicioDate = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaVigenciaFin) {
            registro.fechaVigenciaFin = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaFinFormato = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaFinDate = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaAlta) {
            registro.fechaAlta = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAltaFormato = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAltaDate = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaBaja) {
            registro.fechaBaja = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaBajaFormato = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaBajaDate = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaReporteAlta) {
            registro.fechaReporteAlta = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaReporteAltaFormato = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaReporteAltaDate = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaReporteBaja) {
            registro.fechaReporteBaja = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaReporteBajaFormato = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaReporteBajaDate = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.Certificado) {
            var certificado = this.certificadoDeJSON(json.Certificado);
            registro.certificado = certificado;
        }

        if(json.CoberturaIncisoCertificados) {
            registro.coberturas = [];
            registro.idsCoberturas = [];
            for (let i = 0; i < json.CoberturaIncisoCertificados.length; i++) {
                const element = json.CoberturaIncisoCertificados[i];
                if(element.Cobertura) {
                    var cobertura = this.coberturaDeJSON(element.Cobertura);
                    cobertura.sumaAseguradaCertificado = element.sumaAsegurada;
                    registro.coberturas.push(cobertura);
                    registro.idsCoberturas.push(cobertura.id);
                }
            }
        }

        if(json.DeducibleCertificados) {
            registro.deducibles = [];
            registro.idsDeducibles = [];
            for (let i = 0; i < json.DeducibleCertificados.length; i++) {
                const element = json.DeducibleCertificados[i];
                var deducible = this.deducibleCertificadoDeJSON(element);
                registro.deducibles.push(deducible);
                registro.idsDeducibles.push(deducible.id);
            }
        }

        return registro;
    }

    public incisoHistoricoCertificadoDeJSON = (json: any): IncisoHistoricoCertificado => {
        if(!json) return null;
        var registro = new IncisoHistoricoCertificado(
            json.id,
            json.nombre,
            json.declarativo,
            json.descripcion,
            json.sumaAsegurada,
            json.primaFacturada,
            json.primaSinDependientes,
            json.primaCon1Dependiente,
            json.primaConMasDependientes,
            json.primaAnualizada,
            json.primaEnDeposito,
            json.tipoCambio,
            json.porcentajeTasa,
            json.porcentajePrimaDeposito,
            json.tarifa,
            json.tipoTasa,
            json.estado,
            json.fechaVigenciaInicio,
            json.fechaVigenciaFin,
            json.fechaAlta,
            json.fechaBaja,
            json.fechaReporteAlta,
            json.fechaReporteBaja,
            json.CertificadoId,
            json.EmpresaId,
            json.RazonPerdidaId,
        );

        if(!registro.primaSinDependientes && registro.primaFacturada) registro.primaSinDependientes = registro.primaFacturada;

        if(registro.estado) {
            var index = this.indexOf(this.apiURLs.estadosIncisosCertificados, 'id', registro.estado);
            if(index != -1) registro.nombreEstado = this.apiURLs.estadosIncisosCertificados[index].nombre;
        }

        if(json.fechaVigenciaInicio) {
            registro.fechaVigenciaInicio = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaInicioFormato = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaInicioDate = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaVigenciaFin) {
            registro.fechaVigenciaFin = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaFinFormato = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaFinDate = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaAlta) {
            registro.fechaAlta = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAltaFormato = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAltaDate = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaBaja) {
            registro.fechaBaja = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaBajaFormato = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaBajaDate = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaReporteAlta) {
            registro.fechaReporteAlta = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaReporteAltaFormato = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaReporteAltaDate = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaReporteBaja) {
            registro.fechaReporteBaja = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaReporteBajaFormato = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaReporteBajaDate = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.Certificado) {
            var certificado = this.certificadoDeJSON(json.Certificado);
            registro.certificado = certificado;
        }

        // if(json.CoberturaIncisoCertificados) {
        //     registro.coberturas = [];
        //     registro.idsCoberturas = [];
        //     for (let i = 0; i < json.CoberturaIncisoCertificados.length; i++) {
        //         const element = json.CoberturaIncisoCertificados[i];
        //         if(element.Cobertura) {
        //             var cobertura = this.coberturaDeJSON(element.Cobertura);
        //             cobertura.sumaAseguradaCertificado = element.sumaAsegurada;
        //             registro.coberturas.push(cobertura);
        //             registro.idsCoberturas.push(cobertura.id);
        //         }
        //     }
        // }

        // if(json.DeducibleCertificados) {
        //     registro.deducibles = [];
        //     registro.idsDeducibles = [];
        //     for (let i = 0; i < json.DeducibleCertificados.length; i++) {
        //         const element = json.DeducibleCertificados[i];
        //         var deducible = this.deducibleCertificadoDeJSON(element);
        //         registro.deducibles.push(deducible);
        //         registro.idsDeducibles.push(deducible.id);
        //     }
        // }

        return registro;
    }

    public valorCertificadoDeJSON = (json: any): ValorCertificado => {
        if(!json) return null;
        var registro = new ValorCertificado(
            json.id,
            json.valor,
            json.texto,
            json.fecha,
            json.CertificadoId,
            json.CampoTipoCertificadoId,
        );

        if(registro.valor && !registro.fecha) {
            registro.fecha = this.convertirFecha(registro.valor, 'DD/MM/YYYY', 'YYYY-MM-DD');
        }

        if(registro.fecha) {
            registro.fecha = this.convertirFecha(registro.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaDate = this.convertirFecha(registro.fecha, 'YYYY-MM-DD', 'date');
        }


        if(json.CampoTipoCertificado) {
            var campo = this.campoTipoCertificadoDeJSON(json.CampoTipoCertificado);
            registro.campo = campo;
        }

        return registro;
    }

    public tipoReclamoDeJSON = (json: any): TipoReclamo => {
        if(!json) return null;
        var registro = new TipoReclamo(
            json.id,
            json.nombre,
            json.observaciones,
            json.codigo,
            json.codigoCompatibilidad,
            json.procedimiento,
            json.duracion,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.CampoTipoReclamos) {
            registro.campos = [];
            for (let i = 0; i < json.CampoTipoReclamos.length; i++) {
                const element = json.CampoTipoReclamos[i];
                var campo = this.campoTipoReclamoDeJSON(element);
                registro.campos.push(campo);
            }
        }

        if(json.Ramo) {
            registro.ramo = this.ramoDeJSON(json.Ramo);
            registro.nombreConRamo = registro.ramo.nombre + ' - ' + registro.nombre;
        }
        else {
            registro.nombreConRamo = registro.nombre;
        }

        return registro;
    }

    public campoTipoReclamoDeJSON = (json: any): CampoTipoReclamo => {
        if(!json) return null;
        var registro = new CampoTipoReclamo(
            json.id,
            json.nombre,
            json.obligatorio,
            json.activo,
            json.tipo,
            json.orden,
            json.TipoReclamoId,
            json.EmpresaId,
        );

        switch(registro.tipo) {
            // Valor
            case 'porcentaje': {
                registro.usaPorcentaje = true;
                break;
            }
            case 'moneda': {
                registro.usaMoneda = true;
                break;
            }
            case 'numerico': {
                registro.usaValor = true;
                break;
            }
            // Fecha
            case 'fecha': {
                registro.usaFecha = true;
                break;
            }
            // Texto
            default: {
                registro.usaTexto = true;
                break;
            }
        }

        return registro;
    }

    public valorReclamoDeJSON = (json: any): ValorReclamo => {
        if(!json) return null;
        var registro = new ValorReclamo(
            json.id,
            json.valor,
            json.texto,
            json.fecha,
            json.ReclamoId,
            json.CampoTipoReclamoId,
        );

        if(json.CampoTipoReclamo) {
            var campo = this.campoTipoReclamoDeJSON(json.CampoTipoReclamo);
            registro.campo = campo;
        }

        return registro;
    }

    public convertirIncisoAIncisoCertificado(inciso: Inciso, CertificadoId: number, EmpresaId: number): IncisoCertificado {
        if(inciso) {
            var nuevoInciso = new IncisoCertificado(null, inciso.nombre, inciso.declarativo, inciso.acumulaSuma, inciso.descripcion, 0, 0, 0, 0, 0, 0, 0, null, null, null, null, 'tasa', null, null, null, null, null, null, null, CertificadoId, EmpresaId, null);
            nuevoInciso.coberturas = inciso.coberturas;
            nuevoInciso.idsCoberturas = inciso.idsCoberturas;
            nuevoInciso.idsDeducibles = inciso.idsDeducibles;
            // Copias de deducibles
            nuevoInciso.deducibles = [];
            for (let i = 0; i < inciso.deducibles.length; i++) {
                const element = inciso.deducibles[i];
                var nuevoDeducible = this.convertirDeducibleADeducibleCertificado(element, EmpresaId);
                nuevoInciso.deducibles.push(nuevoDeducible);
            }
            return nuevoInciso;
        }
        else return null;
    }

    convertirDeducibleADeducibleCertificado(deducible: Deducible, EmpresaId: number) {
        if(deducible) {
            var nuevoDeducible = new DeducibleCertificado(null, deducible.nombre, deducible.porcentaje, deducible.tipoBase, deducible.dias, deducible.valorMaximo, deducible.valorMinimo, deducible.valorFijo, deducible.tipoCambio, deducible.observaciones, deducible.participacion, null, EmpresaId);
            return nuevoDeducible;
        }
        else return null;
    }

    copiarCobertura(cobertura: Cobertura) {
        if(cobertura) {
            var nuevaCobertura = new Cobertura(cobertura.id, cobertura.codigo, cobertura.nombre, cobertura.codigoAnterior, cobertura.condicionEspecial, cobertura.activo, cobertura.RamoId, cobertura.EmpresaId);
            return nuevaCobertura;
        }
        else return null;
    }

    public deducibleDeJSON = (json: any): Deducible => {
        if(!json) return null;
        var registro = new Deducible(
            json.id,
            json.nombre,
            json.porcentaje,
            json.tipoBase,
            json.dias,
            json.valorMaximo,
            json.valorMinimo,
            json.valorFijo,
            json.tipoCambio,
            json.observaciones,
            json.participacion,
            json.IncisoId,
            json.EmpresaId,
        );

        if(json.Inciso) {
            var inciso = this.incisoDeJSON(json.Inciso);
            registro.inciso = inciso;
        }

        return registro;
    }

    public deducibleCertificadoDeJSON = (json: any): DeducibleCertificado => {
        if(!json) return null;
        var registro = new DeducibleCertificado(
            json.id,
            json.nombre,
            json.porcentaje,
            json.tipoBase,
            json.dias,
            json.valorMaximo,
            json.valorMinimo,
            json.valorFijo,
            json.tipoCambio,
            json.observaciones,
            json.participacion,
            json.IncisoCertificadoId,
            json.EmpresaId,
        );

        registro.nombreDeducible = registro.nombre;
        if(registro.porcentaje) registro.nombreDeducible += ' (' + registro.porcentaje + '%)';

        return registro;
    }

    public deducibleMonedaCertificadoDeJSON = (json: any): DeducibleMonedaCertificado => {
        if(!json) return null;
        var registro = new DeducibleMonedaCertificado(
            json.id,
            json.nombre,
            json.porcentaje,
            json.tipoBase,
            json.dias,
            json.valorMaximo,
            json.valorMinimo,
            json.valorFijo,
            json.tipoCambio,
            json.observaciones,
            json.CertificadoId,
            json.PolizaId,
            json.MonedaId,
            json.EmpresaId,
        );

        registro.nombreDeducible = registro.nombre;
        if(registro.porcentaje) registro.nombreDeducible += ' (' + registro.porcentaje + '%)';

        return registro;
    }

    public areaRamoDeJSON = (json: any): AreaRamo => {
        if(!json) return null;
        var registro = new AreaRamo(
            json.id,
            json.nombre,
            json.observaciones,
        );

        return registro;
    }

    public ramoDeJSON = (json: any): Ramo => {
        if(!json) return null;
        var registro = new Ramo(
            json.id,
            json.nombre,
            json.observaciones,
            json.tipoBeneficiario,
            json.mostrarFacturasReclamo,
            json.gastosMedicos,
            json.activo,
            json.usarDatosVehiculos,
            json.usarReclamoMonedaInternacional,
            json.esconderPrimasDiferenciadasDependientes,
            json.liquidacionReclamoGastosMedicosMayores,
            json.codigoAnterior,
            json.AreaRamoId,
            json.EmpresaId,
        );

        if(json.AreaRamo) {
            var area = this.areaRamoDeJSON(json.AreaRamo);
            registro.area = area;
            registro.nombreAreaRamo = area.nombre;
        }

        if(registro.tipoBeneficiario) {
            var index = this.indexOf(this.apiURLs.tiposBeneficiarios, 'id', json.tipoBeneficiario);
            if(index != -1) registro.nombreTipoBeneficiario = this.apiURLs.tiposBeneficiarios[index].nombre;
        }

        return registro;
    }

    public razonRamoDeJSON = (json: any): RazonRamo => {
        if(!json) return null;
        var registro = new RazonRamo(
            json.id,
            json.nombre,
            json.descripcion,
            json.isDeleted,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.Ramo) {
            var ramo = this.ramoDeJSON(json.Ramo);
            registro.ramo = ramo;
        }

        return registro;
    }

    public tipoSiniestroDeJSON = (json: any): TipoSiniestro => {
        if(!json) return null;
        var registro = new TipoSiniestro(
            json.id,
            json.nombre,
            json.descripcion,
            json.isDeleted,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.Ramo) {
            var ramo = this.ramoDeJSON(json.Ramo);
            registro.ramo = ramo;
        }

        return registro;
    }

    public documentoUsuarioDeJSON = (json: any): DocumentoUsuario => {
        if(!json) return null;
        var registro: DocumentoUsuario = new DocumentoUsuario(
            json.id,
            json.titulo,
            json.observaciones,
            json.extension,
            json.archivo,
            json.FormularioClienteId,
            json.UsuarioId,
            json.EmpresaId,
        );

        return registro;
    }

    public documentoPolizaDeJSON = (json: any): DocumentoPoliza => {
        if(!json) return null;
        var registro: DocumentoPoliza = new DocumentoPoliza(
            json.id,
            json.nombre,
            json.descripcion,
            json.extension,
            json.link,
            json.CertificadoId,
            json.PolizaId,
            json.SolicitudPolizaId,
            json.UsuarioId,
            json.EmpresaId,
        );

        return registro;
    }

    public envioSolicitudDeJSON = (json: any): EnvioSolicitud => {
        if(!json) return null;
        var registro: EnvioSolicitud = new EnvioSolicitud(
            json.id,
            json.destinatarios,
            json.asunto,
            json.de,
            json.fecha,
            json.observaciones,
            json.SolicitudPolizaId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        if(json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.usuario.nombreCompleto;
        }

        return registro;
    }

    public porcentajeCoasegurosDeJSON = (json: any): PorcentajeCoaseguros => {
        if(!json) return null;
        var registro: PorcentajeCoaseguros = new PorcentajeCoaseguros(
            json.id,
            json.aseguradora,
            json.porcentaje,
            json.AseguradoraId,
            json.PolizaId,
        );

        if(json.Aseguradora) {
            registro.aseguradora = this.aseguradoraDeJSON(json.Aseguradora);
        }

        return registro;
    }

    public pagadorCertificadoDeJSON = (json: any): PagadorCertificado => {
        if(!json) return null;
        var registro: PagadorCertificado = new PagadorCertificado(
            json.id,
            json.nombre,
            json.porcentaje,
            json.digitos,
            json.metodoPago,
            json.fechaVencimiento,
            json.banco,
            json.tipoCuenta,
            json.ClienteId,
            json.CertificadoId,
            json.EmpresaId,
        );

        if(json.Cliente) {
            registro.cliente = this.usuarioDeJSON(json.Cliente);
            registro.clientes = [registro.cliente];
        }

        if(json.Certificado) {
            registro.certificado = this.certificadoDeJSON(json.Certificado);
        }

        return registro;
    }

    public pagadorPolizaDeJSON = (json: any): PagadorPoliza => {
        if(!json) return null;
        var registro: PagadorPoliza = new PagadorPoliza(
            json.id,
            json.nombre,
            json.porcentaje,
            json.ClienteId,
            json.PolizaId,
        );

        if(json.Cliente) {
            registro.cliente = this.usuarioDeJSON(json.Cliente);
            registro.clientes = [registro.cliente];
        }

        return registro;
    }

    public areaDeJSON = (json: any): Area => {
        if(!json) return null;
        var registro = new Area(
            json.id,
            json.nombre,
            json.descripcion,
            json.clientes,
            json.polizas,
            json.prospectos,
            json.comisiones,
            json.reclamos,
            json.emergencias,
            json.cobros,
            json.controlCalidad,
            json.mensajeria,
            json.mapas,
            json.plantillasRamos,
            json.clientesAnalytics,
            json.polizasAnalytics,
            json.prospectosAnalytics,
            json.comisionesAnalytics,
            json.reclamosAnalytics,
            json.emergenciasAnalytics,
            json.cobrosAnalytics,
            json.controlCalidadAnalytics,
            json.mensajeriaAnalytics,
            json.mapasAnalytics,
            json.analytics,
            json.empresas,
            json.datosGenerales,
            json.paisesMonedas,
            json.areasAccesos,
            json.usuarios,
            json.aseguradoras,
            json.ramos,
            json.formasCobro,
            json.estatusCobro,
            json.tiposGestion,
            json.plantillasMapas,
            json.matrizRiesgo,
            json.usuariosExternos,
            json.bitacora,
            json.homepage,
            json.cliente,
            json.mostrarEjecutivos,
            json.EmpresaId,
        );
        return registro;
    }

    public informacionClienteDeJSON = (json: any): InformacionCliente => {
        if(!json) return null;
        var registro = new InformacionCliente(
            json.id,
            json.tipo,
            json.clasificacion,
            json.clase,
            json.activo,
            json.nit,
            json.numeroRegistro,
            json.direccionFiscal,
            json.requiereFacturaFisica,
            json.observacionesFacturacion,
            json.razonSocial,
            json.lugarTrabajo,
            json.direccionTrabajo,
            json.direccionAdicional,
            json.ocupacion,
            json.lugarCobro,
            json.lugarCorrespondencia,
            json.tipoDocumento,
            json.numeroDocumento,
            json.pasaporte,
            json.lugarEmisionDocumento,
            json.ciudadania,
            json.clienteDesde,
            json.personaExpuestaPoliticamente,
            json.actuaEnNombrePropio,
            json.archivoDocumento,
            json.archivoRecibo,
            json.nombreContacto,
            json.puestoContacto,
            json.observacionesContacto,
            json.tipoClienteRiesgo,
            json.paisNacimiento,
            json.actividadGeneradoraRecursos,
            json.paisActividadEconomica,
            json.tipoActivosPropiosActividadCliente,
            json.productosServiciosUtilizados,
            json.caracter,
            json.valorMontoAsegurado,
            json.medioPago,
            json.tipoIngresosOrigenRecursos,
            json.formularioKYC,
            json.nivelRiesgo,
            json.giroNegocio,
            json.representanteLegal,
            json.aplicaVisacuota,
            json.cantidadVisacuota,

            json.tipoDocumentoRiesgo,
            json.numeroDocumentoRiesgo,
            json.estadoCivil,
            json.notasAdicionales,
            json.fechaVencimientoDocumento,
            json.fechaFallecido,
            json.fechaActualizacionKYC,
            json.existeFormularioKYC,
            json.presentoDocumentoIdentificacionKYC,
            json.constanciaDomicilioKYC,
            json.firmaDeclaracionJuradaKYC,
            json.presentoPersoneriaVigenteKYC,
            json.consentimientoInformadoKYC,

            json.nivelRiesgoTipoCliente,
            json.nivelRiesgoActividadGeneradoraRecursos,
            json.nivelRiesgoCiudadania,
            json.nivelRiesgoPaisNacimiento,
            json.nivelRiesgoPaisActividadEconomica,
            json.nivelRiesgoTipoActivoPropio,
            json.nivelRiesgoProductos,
            json.nivelRiesgoCaracter,
            json.nivelRiesgoValorAsegurado,
            json.nivelRiesgoMedioPago,
            json.nivelRiesgoTipoIngresos,
        );

        if(json.clasificacion) {
            var index = this.indexOf(this.apiURLs.clasificaciones, 'id', json.clasificacion);
            if(index != -1) registro.clasificacionNombre = this.apiURLs.clasificaciones[index].nombre;
        }

        if(json.tipoDocumento) {
            var index = this.indexOf(this.apiURLs.tiposDocumentos, 'id', json.tipoDocumento);
            if(index != -1) registro.tipoDocumentoNombre = this.apiURLs.tiposDocumentos[index].nombre;
        }

        if(json.tipo) {
            var index = this.indexOf(this.apiURLs.tiposClientes, 'id', json.tipo);
            if(index != -1) registro.tipoClienteNombre = this.apiURLs.tiposClientes[index].nombre;
        }

        if(json.clienteDesde) {
            registro.clienteDesde = this.convertirFecha(json.clienteDesde, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.clienteDesdeFormato = this.convertirFecha(json.clienteDesde, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.clienteDesdeDate = this.convertirFecha(json.clienteDesde, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    beneficiarioDependienteCertificadoDeJSON = (json: any): BeneficiarioDependienteCertificado => {
        if(!json) return null;
        var registro = new BeneficiarioDependienteCertificado(
            json.id,
            json.nombre,
            json.tipo,
            json.genero,
            json.parentesco,
            json.estado,
            json.fechaNacimiento,
            json.fechaAlta,
            json.fechaBaja,
            json.fechaReporteAlta,
            json.fechaReporteBaja,
            json.fechaVigenciaInicio,
            json.fechaVigenciaFin,
            json.observaciones,
            json.porcentajeBeneficio,
            json.CertificadoId,
        );

        if(json.fechaNacimiento) {
            registro.fechaNacimiento = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaNacimientoFormato = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaNacimientoDate = this.convertirFecha(json.fechaNacimiento, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaAlta) {
            registro.fechaAlta = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAltaFormato = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAltaDate = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaBaja) {
            registro.fechaBaja = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaBajaFormato = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaBajaDate = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaReporteAlta) {
            registro.fechaReporteAlta = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaReporteAltaFormato = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaReporteAltaDate = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaReporteBaja) {
            registro.fechaReporteBaja = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaReporteBajaFormato = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaReporteBajaDate = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaVigenciaInicio) {
            registro.fechaVigenciaInicio = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaInicioFormato = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaInicioDate = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaVigenciaFin) {
            registro.fechaVigenciaFin = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaFinFormato = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaFinDate = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'date');
        }

        return registro;
    }

    public empresaDeJSON = (json: any): Empresa => {
        if(!json) return null;
        var registro = new Empresa(
            json.id,
            json.nombre,
            json.direccion,
            json.telefono,
            json.telefonoEmergencia,
            json.website,
            json.tipoMatrizRiesgo,
            json.emailServicioAlCliente,
            json.emailSoporteTecnico,
            json.logo,
            json.logoDocumentos,
            json.reglasPais,
            json.registro,
            json.visualizarTodosProspectos,
            json.bandera,
            json.MonedaId,
            json.RazonPerdidaId,
        );

        if(json.ConfiguracionEmpresas && json.ConfiguracionEmpresas.length > 0) {
            registro.configuracionEmpresa = this.configuracionEmpresaDeJSON(json.ConfiguracionEmpresas[0]);
        }

        if(json.ImpuestoEmpresas) {
            registro.impuestos = [];
            for (let i = 0; i < json.ImpuestoEmpresas.length; i++) {
                const element = json.ImpuestoEmpresas[i];
                registro.impuestos.push(this.impuestoEmpresaDeJSON(element));
            }
        }

        if(json.FormularioClientes) {
            registro.formulariosClientes = [];
            for (let i = 0; i < json.FormularioClientes.length; i++) {
                const element = json.FormularioClientes[i];
                registro.formulariosClientes.push(this.formularioClienteDeJSON(element));
            }
        }

        if(json.MonedaEmpresas) {
            registro.monedas = [];
            registro.idsMonedas = [];
            for (let i = 0; i < json.MonedaEmpresas.length; i++) {
                const element = json.MonedaEmpresas[i];
                if(element.Moneda) {
                    var moneda = this.monedaDeJSON(element.Moneda);
                    moneda.tipoCambioDolares = element.tipoCambioDolares;
                    registro.monedas.push(moneda);
                    registro.idsMonedas.push(element.MonedaId);
                }
            }
        }

        if(json.moneda) json.Moneda = json.moneda;
        if(json.Moneda) {
            registro.moneda = this.monedaDeJSON(json.Moneda);
        }

        return registro;
    }

    public productoDeJSON = (json: any): Producto => {
        if(!json) return null;
        var registro = new Producto(
            json.id,
            json.nombre,
            json.codigoAnterior,
            json.tipoOtrosGastos,
            json.valorOtrosGastos,
            json.porcentajeOtrosGastos,
            json.tipoGastosEmision,
            json.valorGastosEmision,
            json.porcentajeGastosEmision,
            json.edadLimiteDependientesAsegurados,
            json.edadLimiteReduccionBeneficios1,
            json.edadLimiteReduccionBeneficios2,
            json.usaAnioCalendario,
            json.riesgo,
            json.activo,
            json.isDeleted,
            json.liquidarConFraccionamiento,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.Ramo) {
            registro.ramo = this.ramoDeJSON(json.Ramo);
        }

        if(json.AseguradoraProductos) {
            registro.aseguradoras = [];
            registro.idsAseguradoras = [];
            for (let i = 0; i < json.AseguradoraProductos.length; i++) {
                const element = json.AseguradoraProductos[i];
                if(element.Aseguradora) {
                    var aseguradora = this.aseguradoraDeJSON(element.Aseguradora);
                    registro.aseguradoras.push(aseguradora);
                    if(registro.nombreAseguradoras.trim() != '') registro.nombreAseguradoras += ', ';
                    registro.nombreAseguradoras += aseguradora.nombre;
                    registro.idsAseguradoras.push(element.AseguradoraId);
                }
            }
        }

        if(json.ImpuestoProductos) {
            registro.impuestos = [];
            registro.idsImpuestos = [];
            for (let i = 0; i < json.ImpuestoProductos.length; i++) {
                const element = json.ImpuestoProductos[i];
                if(element.ImpuestoEmpresa) {
                    registro.impuestos.push(this.impuestoEmpresaDeJSON(element.ImpuestoEmpresa));
                    registro.idsImpuestos.push(element.ImpuestoEmpresaId);
                }
            }
        }

        if(json.IncisoProductos) {
            registro.incisos = [];
            registro.idsIncisos = [];
            for (let i = 0; i < json.IncisoProductos.length; i++) {
                const element = json.IncisoProductos[i];
                if(element.Inciso) {
                    registro.incisos.push(this.incisoDeJSON(element.Inciso));
                    registro.idsIncisos.push(element.IncisoId);
                }
            }
        }

        if(json.ComisionAseguradoraProductos) {
            registro.comisiones = [];
            for (let i = 0; i < json.ComisionAseguradoraProductos.length; i++) {
                const element = json.ComisionAseguradoraProductos[i];
                registro.comisiones.push(this.comisionAseguradoraProductoDeJSON(element));
            }
        }

        if(json.GastoProductos) {
            registro.gastos = [];
            for (let i = 0; i < json.GastoProductos.length; i++) {
                const element = json.GastoProductos[i];
                registro.gastos.push(this.gastoProductoDeJSON(element));
            }
        }

        return registro;
    }

    public configuracionEmpresaDeJSON = (json: any): ConfiguracionEmpresa => {
        if(!json) return null;
        var registro = new ConfiguracionEmpresa(
            json.id,
            json.smtp,
            json.pop,
            json.puertoSMTP,
            json.puertoPOP,
            json.requiereAutenticacion,
            json.diasVencimientoAcceso,
            json.minimoCaracteresContrasena,
            json.aplicaVencimientoContrasena,
            json.incisosAplicaGastosEmision,
            json.incisosNoProrrateo,
            json.tiposClientesRiesgo,
            json.paisesRiesgoAlto,
            json.paisesRiesgoMedio,
            json.tiposActivosPropiosRiesgo,
            json.caracteresRiesgo,
            json.valoresMontoAseguradoRiesgo,
            json.mediosPagoRiesgo,
            json.ingresosOrigenRecursosRiesgo,
            json.rangosRiesgo,
            json.EmpresaId,
        );

        if(registro.incisosAplicaGastosEmision) {
            registro.incisosAplicaGastosEmisionArray = registro.incisosAplicaGastosEmision.split('|').map(x => { return { nombre: x } });
        }

        if(registro.incisosNoProrrateo) {
            registro.incisosNoProrrateoArray = registro.incisosNoProrrateo.split('|').map(x => { return { nombre: x } });
        }

        if(registro.tiposClientesRiesgo) registro.tiposClientesRiesgoArray = JSON.parse(registro.tiposClientesRiesgo);
        if(registro.paisesRiesgoAlto) registro.paisesRiesgoAltoArray = JSON.parse(registro.paisesRiesgoAlto);
        if(registro.paisesRiesgoMedio) registro.paisesRiesgoMedioArray = JSON.parse(registro.paisesRiesgoMedio);
        if(registro.tiposActivosPropiosRiesgo) registro.tiposActivosPropiosRiesgoArray = JSON.parse(registro.tiposActivosPropiosRiesgo);
        if(registro.caracteresRiesgo) registro.caracteresRiesgoArray = JSON.parse(registro.caracteresRiesgo);
        if(registro.valoresMontoAseguradoRiesgo) registro.valoresMontoAseguradoRiesgoArray = JSON.parse(registro.valoresMontoAseguradoRiesgo);
        if(registro.mediosPagoRiesgo) registro.mediosPagoRiesgoArray = JSON.parse(registro.mediosPagoRiesgo);
        if(registro.ingresosOrigenRecursosRiesgo) registro.ingresosOrigenRecursosRiesgoArray = JSON.parse(registro.ingresosOrigenRecursosRiesgo);
        if(registro.rangosRiesgo) registro.rangosRiesgoArray = JSON.parse(registro.rangosRiesgo);

        return registro;
    }

    public cambioEstadoPolizaDeJSON = (json: any): CambioEstadoPoliza => {
        if(!json) return null;
        var registro = new CambioEstadoPoliza(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoSiguiente,
            json.UsuarioId,
            json.PolizaId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss');
        }

        if(registro.estadoAnterior) {
            var index = this.indexOf(this.apiURLs.estadosPolizas, 'id', registro.estadoAnterior);
            if(index != -1) registro.nombreEstadoAnterior = this.apiURLs.estadosPolizas[index].nombre;
        }

        if(registro.estadoSiguiente) {
            var index = this.indexOf(this.apiURLs.estadosPolizas, 'id', registro.estadoSiguiente);
            if(index != -1) registro.nombreEstadoSiguiente = this.apiURLs.estadosPolizas[index].nombre;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        return registro;
    }

    public cambioEstadoCertificadoDeJSON = (json: any): CambioEstadoCertificado => {
        if(!json) return null;
        var registro = new CambioEstadoCertificado(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoSiguiente,
            json.UsuarioId,
            json.CertificadoId,
            json.IncisoCertificadoId,
            json.CoberturaIncisoCertificadoId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        if(registro.estadoAnterior) {
            var index = this.indexOf(this.apiURLs.estadosCertificados, 'id', registro.estadoAnterior);
            if(index != -1) registro.nombreEstadoAnterior = this.apiURLs.estadosCertificados[index].nombre;
            else registro.nombreEstadoAnterior = registro.estadoAnterior;
        }

        if(registro.estadoSiguiente) {
            var index = this.indexOf(this.apiURLs.estadosCertificados, 'id', registro.estadoSiguiente);
            if(index != -1) registro.nombreEstadoSiguiente = this.apiURLs.estadosCertificados[index].nombre;
            else registro.nombreEstadoAnterior = registro.estadoSiguiente;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(json.IncisoCertificado) {
            var inciso = this.incisoCertificadoDeJSON(json.IncisoCertificado);
            registro.inciso = inciso;
            registro.nombreInciso = inciso.nombre;
        }

        if(json.CoberturaIncisoCertificado) {
            var coberturaInciso = this.coberturaIncisoCertificadoDeJSON(json.CoberturaIncisoCertificado);
            registro.cobertura = coberturaInciso;
            registro.nombreCobertura = coberturaInciso.nombreCobertura;
        }

        return registro;
    }

    public cambioEstadoDireccionClienteDeJSON = (json: any): CambioEstadoDireccionCliente => {
        if(!json) return null;
        var registro = new CambioEstadoDireccionCliente(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoSiguiente,
            json.UsuarioId,
            json.DireccionClienteId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        return registro;
    }

    public cambioEstadoReclamoDeJSON = (json: any): CambioEstadoReclamo => {
        if(!json) return null;
        var registro = new CambioEstadoReclamo(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoSiguiente,
            json.observaciones,
            json.UsuarioId,
            json.ReclamoId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        if(registro.estadoAnterior) {
            var index = this.indexOf(this.apiURLs.estadosReclamos, 'id', registro.estadoAnterior);
            if(index != -1) registro.nombreEstadoAnterior = this.apiURLs.estadosReclamos[index].nombre;
        }

        if(registro.estadoSiguiente) {
            var index = this.indexOf(this.apiURLs.estadosReclamos, 'id', registro.estadoSiguiente);
            if(index != -1) registro.nombreEstadoSiguiente = this.apiURLs.estadosReclamos[index].nombre;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        return registro;
    }

    public solicitudClienteDeJSON = (json: any): SolicitudCliente => {
        if (!json) return null;
        var registro = new SolicitudCliente(
            json.id,
            json.fecha,
            json.titulo,
            json.descripcion,
            json.estado,
            json.observacionesInternas,
            json.AgrupadorId,
            json.UsuarioId,
            json.ClienteId,
            json.PolizaId,
            json.CertificadoId,
            json.EmpresaId,
        );

        if(registro.descripcion) {
            registro.descripcionResumen = registro.descripcion.substr(0, 50);
            if(registro.descripcion.length > 50) registro.descripcionResumen += '...';
        }

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.usuario.nombreCompleto;
        }

        if (json.Cliente) {
            registro.cliente = this.usuarioDeJSON(json.Cliente);
            registro.nombreCliente = registro.cliente.nombreCompleto;
        }

        if (json.Poliza) {
            registro.poliza = this.polizaDeJSON(json.Poliza);
            registro.nombrePoliza = registro.poliza.nombrePoliza;
        }

        if (json.Certificado) {
            registro.certificado = this.certificadoDeJSON(json.Certificado);
            registro.nombreCertificado = registro.certificado.nombreCertificado;
        }

        if(registro.estado) {
            var index = this.indexOf(this.apiURLs.estadosSolicitudesClientes, 'id', registro.estado);
            if(index != -1) registro.nombreEstado = this.apiURLs.estadosSolicitudesClientes[index].nombre;
        }

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        return registro;
    }

    public documentoMensajeriaDeJSON = (json: any): DocumentoMensajeria => {
        if (!json) return null;
        var registro = new DocumentoMensajeria(
            json.id,
            json.nombre,
            json.descripcion,
            json.extension,
            json.link,
            json.MensajeriaId,
            json.EmpresaId,
        );

        return registro;
    }

    public documentoReclamoDeJSON = (json: any): DocumentoReclamo => {
        if (!json) return null;
        var registro = new DocumentoReclamo(
            json.id,
            json.nombre,
            json.descripcion,
            json.extension,
            json.link,
            json.ReclamoId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.usuario.nombreCompleto;
        }

        return registro;
    }

    public documentoEnvioReclamoDeJSON = (json: any): DocumentoEnvioReclamo => {
        if (!json) return null;
        var registro = new DocumentoEnvioReclamo(
            json.id,
            json.titulo,
            json.descripcion,
            json.entregado,
            json.enviarAAseguradora,
            json.enviarACliente,
            json.devolverAAseguradora,
            json.ReclamoId,
            json.EmpresaId,
        );

        return registro;
    }

    public documentoEnvioGestionCobroDeJSON = (json: any): DocumentoEnvioGestionCobro => {
        if (!json) return null;
        var registro = new DocumentoEnvioGestionCobro(
            json.id,
            json.titulo,
            json.descripcion,
            json.entregado,
            json.enviarAAseguradora,
            json.enviarACliente,
            json.devolverAAseguradora,
            json.link,
            json.GestionCobroId,
            json.EmpresaId,
        );
        registro.extension = json.extension;

        return registro;
    }

    public cambioEstadoMensajeriaDeJSON = (json: any): CambioEstadoMensajeria => {
        if(!json) return null;
        var registro = new CambioEstadoMensajeria(
            json.id,
            json.fecha,
            json.estadoAnterior,
            json.estadoSiguiente,
            json.diasEstadoAnterior,
            json.observaciones,
            json.UsuarioId,
            json.UsuarioAsignadoId,
            json.MensajeriaId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm:ss', this.apiURLs.defaultTimezone);
        }

        if(registro.estadoAnterior) {
            var index = this.indexOf(this.apiURLs.estadosMensajeria, 'id', registro.estadoAnterior);
            if(index != -1) registro.nombreEstadoAnterior = this.apiURLs.estadosMensajeria[index].nombre;
        }

        if(registro.estadoSiguiente) {
            var index = this.indexOf(this.apiURLs.estadosMensajeria, 'id', registro.estadoSiguiente);
            if(index != -1) registro.nombreEstadoSiguiente = this.apiURLs.estadosMensajeria[index].nombre;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(json.UsuarioAsignado) {
            var usuario = this.usuarioDeJSON(json.UsuarioAsignado);
            registro.usuarioAsignado = usuario;
            registro.nombreUsuarioAsignado = usuario.nombreCompleto;
        }

        return registro;
    }

    public tipoCertificadoDeJSON = (json: any): TipoCertificado => {
        if(!json) return null;
        var registro = new TipoCertificado(
            json.id,
            json.nombre,
            json.observaciones,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.CampoTipoCertificados) {
            registro.campos = [];
            for (let i = 0; i < json.CampoTipoCertificados.length; i++) {
                const element = json.CampoTipoCertificados[i];
                var campo = this.campoTipoCertificadoDeJSON(element);
                registro.campos.push(campo);
            }
        }

        return registro;
    }

    public campoTipoCertificadoDeJSON = (json: any): CampoTipoCertificado => {
        if(!json) return null;
        var registro = new CampoTipoCertificado(
            json.id,
            json.nombre,
            json.obligatorio,
            json.activo,
            json.tipo,
            json.orden,
            json.campoAuxiliar,
            json.TipoCertificadoId,
            json.EmpresaId,
        );

        switch(registro.tipo) {
            // Valor
            case 'porcentaje': {
                registro.usaPorcentaje = true;
                break;
            }
            case 'moneda': {
                registro.usaMoneda = true;
                break;
            }
            case 'numerico': {
                registro.usaValor = true;
                break;
            }
            // Fecha
            case 'fecha': {
                registro.usaFecha = true;
                break;
            }
            // Texto
            default: {
                registro.usaTexto = true;
                break;
            }
        }

        return registro;
    }

    public comisionAseguradoraProductoDeJSON = (json: any): ComisionAseguradoraProducto => {
        if(!json) return null;
        var registro = new ComisionAseguradoraProducto(
            json.id,
            json.ano,
            json.comisionRecibida,
            json.comisionRepartida,
            json.tipoCambio,
            json.ProductoId,
            json.AseguradoraId,
            json.IncisoId,
        );
        return registro;
    }

    public bitacoraComisionIncisoDeJSON = (json: any): BitacoraComisionInciso => {
        if(!json) return null;
        var registro = new BitacoraComisionInciso(
            json.id,
            json.descripcion,
            json.fecha,
            json.IncisoId,
            json.UsuarioId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        return registro;
    }

    public comisionPolizaIncisoDeJSON = (json: any): ComisionPolizaInciso => {
        if(!json) return null;
        var registro = new ComisionPolizaInciso(
            json.id,
            json.nombreInciso,
            json.primaNeta,
            json.porcentajeComision,
            json.porcentajeComisionRepartida,
            json.comision,
            json.comisionPorPago,
            json.ProductoId,
            json.AseguradoraId,
            json.PolizaId,
            json.IncisoId,
            json.ComisionAseguradoraProductoId,
            json.EmpresaId,
        );
        return registro;
    }

    public gastoProductoDocumentoCobroDeJSON = (json: any): GastoProductoDocumentoCobro => {
        if(!json) return null;
        var registro = new GastoProductoDocumentoCobro(
            json.id,
            json.nombre,
            json.porcentaje,
            json.monto,
            json.MonedaId,
            json.GastoProductoId,
            json.DocumentoCobroId,
        );

        if(json.GastoProducto) {
            registro.gastoProducto = this.gastoProductoDeJSON(json.GastoProducto);
        }

        return registro;
    }

    public impuestoDocumentoCobroDeJSON = (json: any): ImpuestoDocumentoCobro => {
        if(!json) return null;
        var registro = new ImpuestoDocumentoCobro(
            json.id,
            json.nombre,
            json.porcentaje,
            json.monto,
            json.MonedaId,
            json.ImpuestoEmpresaId,
            json.DocumentoCobroId,
        );

        if(json.ImpuestoEmpresa) {
            registro.impuesto = this.impuestoEmpresaDeJSON(json.ImpuestoEmpresa);
        }

        return registro;
    }

    public gastoProductoDocumentoCreditoDeJSON = (json: any): GastoProductoDocumentoCredito => {
        if(!json) return null;
        var registro = new GastoProductoDocumentoCredito(
            json.id,
            json.nombre,
            json.porcentaje,
            json.monto,
            json.MonedaId,
            json.GastoProductoId,
            json.DocumentoCreditoId,
        );

        if(json.GastoProducto) {
            registro.gastoProducto = this.gastoProductoDeJSON(json.GastoProducto);
        }

        return registro;
    }

    public impuestoDocumentoCreditoDeJSON = (json: any): ImpuestoDocumentoCredito => {
        if(!json) return null;
        var registro = new ImpuestoDocumentoCredito(
            json.id,
            json.nombre,
            json.porcentaje,
            json.monto,
            json.MonedaId,
            json.ImpuestoEmpresaId,
            json.DocumentoCreditoId,
        );

        if(json.ImpuestoEmpresa) {
            registro.impuesto = this.impuestoEmpresaDeJSON(json.ImpuestoEmpresa);
        }

        return registro;
    }

    public detalleDeclaracionDeJSON = (json: any): DetalleDeclaracion => {
        if(!json) return null;
        var registro = new DetalleDeclaracion(
            json.id,
            json.numeroEmbarque,
            json.fechaInicioEmbarque,
            json.numeroFactura,
            json.descripcionMercaderia,
            json.via,
            json.origen,
            json.destino,
            json.valorEmbarque,
            json.gastosAdicionales,
            json.totalDeclarado,
            json.tasa,
            json.primaNeta,
            json.DeclaracionId,
            json.EmpresaId,
        );

        return registro;
    }

    public gastoProductoDeJSON = (json: any): GastoProducto => {
        if(!json) return null;
        var registro = new GastoProducto(
            json.id,
            json.nombre,
            json.tipo,
            json.porcentaje,
            json.montoFijo,
            json.MonedaId,
            json.ProductoId,
        );
        return registro;
    }

    public declaracionDeJSON = (json: any): Declaracion => {
        if(!json) return null;
        var registro = new Declaracion(
            json.id,
            json.descripcion,
            json.desde,
            json.hasta,
            json.montoDeclarado,
            json.tasa,
            json.primaNeta,
            json.tipoCambio,
            json.numeroEndoso,
            json.fueDeclarado,
            json.ingresoDetalle,
            json.CertificadoId,
            json.IncisoCertificadoId,
            json.EmpresaId,
        );

        registro.meta = {
            montoDeclarado: registro.montoDeclarado,
            sobreLimite: registro.sobreLimite,
        }

        if(json.desde) {
            registro.desde = this.convertirFecha(json.desde, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.desdeFormato = this.convertirFecha(json.desde, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.desdeDate = this.convertirFecha(json.desde, 'YYYY-MM-DD', 'date');
        }

        if(json.hasta) {
            registro.hasta = this.convertirFecha(json.hasta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.hastaFormato = this.convertirFecha(json.hasta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.hastaDate = this.convertirFecha(json.hasta, 'YYYY-MM-DD', 'date');
        }

        if(json.Certificado) {
            var certificado = this.certificadoDeJSON(json.Certificado);
            registro.certificado = certificado;
        }

        if(json.DetalleDeclaracions) {
            registro.detalles = [];
            for (let i = 0; i < json.DetalleDeclaracions.length; i++) {
                const element = json.DetalleDeclaracions[i];
                var detalle = this.detalleDeclaracionDeJSON(element);
                registro.detalles.push(detalle);
            }
        }

        return registro;
    }

    public impuestoEmpresaDeJSON = (json: any): ImpuestoEmpresa => {
        if(!json) return null;
        var registro = new ImpuestoEmpresa(
            json.id,
            json.nombre,
            json.tipo,
            json.porcentaje,
            json.montoFijo,
            json.EmpresaId,
        );
        return registro;
    }

    public formularioClienteDeJSON = (json: any): FormularioCliente => {
        if(!json) return null;
        var registro = new FormularioCliente(
            json.id,
            json.nombre,
            json.descripcion,
            json.plantilla1,
            json.plantilla2,
            json.EmpresaId,
        );
        return registro;
    }

    public monedaDeJSON = (json: any): Moneda => {
        if(!json) return null;
        var registro = new Moneda(
            json.id,
            json.nombre,
            json.nombrePlural,
            json.simbolo,
        );
        return registro;
    }

    public seguimientoCobroDeJSON = (json: any): SeguimientoCobro => {
        if(!json) return null;
        var registro = new SeguimientoCobro(
            json.id,
            json.numero,
            json.observaciones,
            json.fecha,
            json.DocumentoCobroId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(registro.observaciones) {
            registro.observacionesResumen = registro.observaciones.substr(0, 100);
            if(registro.observaciones.length > 100) registro.observacionesResumen += '...';
        }

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        return registro;
    }

    public planillaCobroDeJSON = (json: any): PlanillaCobro => {
        if(!json) return null;
        var registro = new PlanillaCobro(
            json.id,
            json.formaPago,
            json.banco,
            json.numeroCheque,
            json.numeroFactura,
            json.observaciones,
            json.fecha,
            json.total,
            json.congelada,
            json.AseguradoraId,
            json.MonedaId,
            json.UsuarioId,
            json.EmpresaId,
        );
        registro.meta.total = registro.total;
        registro.meta.MonedaId = registro.MonedaId;

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(registro.formaPago) {
            var indexFormaPago = this.indexOf(this.apiURLs.formasCobro, 'id', registro.formaPago);
            if(indexFormaPago != -1) registro.formaPagoNombre = this.apiURLs.formasCobro[indexFormaPago].nombre;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
        }

        if(json.Moneda) {
            var moneda = this.monedaDeJSON(json.Moneda);
            registro.moneda = moneda;
            registro.meta.moneda = moneda;
            registro.nombreMoneda = moneda.nombre;
        }

        if(json.Aseguradora) {
            var aseguradora = this.aseguradoraDeJSON(json.Aseguradora);
            registro.aseguradora = aseguradora;
            registro.nombreAseguradora = aseguradora.nombre;
        }

        if(json.DocumentoCobros) {
            registro.documentos = [];
            for (let i = 0; i < json.DocumentoCobros.length; i++) {
                const element = json.DocumentoCobros[i];
                var documento = this.documentoCobroDeJSON(element);
                registro.documentos.push(documento);
            }
        }

        return registro;
    }

    public planillaLiquidacionDeJSON = (json: any): PlanillaLiquidacion => {
        if(!json) return null;
        var registro = new PlanillaLiquidacion(
            json.id,
            json.observaciones,
            json.fecha,
            json.totalPrimaNeta,
            json.totalComision,
            json.diferenciaAceptable,
            json.diferenciaPorcentajeAceptable,
            json.liquidada,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
        }

        if(json.comisionesCalculadas) {
            registro.comisionesCalculadas = json.comisionesCalculadas;
        }

        if(json.DocumentoCobros) {
            registro.documentos = [];
            for (let i = 0; i < json.DocumentoCobros.length; i++) {
                const element = json.DocumentoCobros[i];
                var documento = this.documentoCobroDeJSON(element);
                registro.documentos.push(documento);
            }
        }

        if(json.AplicacionesCredito) {
            registro.aplicacionesCredito = [];
            for (let i = 0; i < json.AplicacionesCredito.length; i++) {
                const element = json.AplicacionesCredito[i];
                var aplicacion = this.aplicacionDocumentoCreditoDeJSON(element);
                registro.aplicacionesCredito.push(aplicacion);
            }
        }

        return registro;
    }

    public documentoCobroDeJSON = (json: any): DocumentoCobro => {
        try {

            if(!json) return null;
            var registro = new DocumentoCobro(
                json.id,
                json.numeroRequisito,
                json.numeroEndoso,
                json.numeroFacturaAseguradora,
                json.etapa,
                json.tipo,
                json.fechaIngreso,
                json.fechaEmision,
                json.fechaPago,
                json.fechaAplicacion,
                json.fechaCambio,
                json.fechaAnulacion,
                json.motivoAnulacion,
                json.numeroPago,
                json.numeroPagoTotal,
                json.primaNeta,
                json.totalEmision,
                json.totalGastos,
                json.totalImpuestos,
                json.totalFraccionamiento,
                json.primaTotal,
                json.formaCobro,
                json.observaciones,
                json.liberado,
                json.rechazado,
                json.razon,
                json.banco,
                json.numeroCheque,
                json.numeroFactura,
                json.chequeRechazado,
                json.razonAnulacion,
                json.multiplesFormasPago,
                json.usaFormaPagoMultiple,
                json.observacionesChequeRechazado,
                json.observacionesPlanilla,
                json.observacionesLiquidacion,
                json.enviadoMensajeria,
                json.ocultarPlanilla,
                json.porcentajeComisionTotal,
                json.porcentajeComisionProductor,
                json.porcentajeComisionEmisor,
                json.porcentajeComisionAgente,
                json.comisionTotal,
                json.comisionProductor,
                json.comisionEmisor,
                json.comisionEmpresa,
                json.comisionAgente,
                json.comisionEmisorAuxiliar,
                json.historialComisiones,
                json.distribucionComision,
                json.CobradorId,
                json.PagadorId,
                json.UsuarioId,
                json.UsuarioAplicadorId,
                json.UsuarioLiquidadorId,
                json.UsuarioAnuladorId,
                json.PolizaId,
                json.MonedaId,
                json.PlanillaCobroId,
                json.MensajeriaId,
                json.AgenteComisionId,
                json.EmpresaId,
                json.PlanillaLiquidacionId,
            );
            if(registro.numeroPago) registro.numeroPagoNombre = registro.numeroPago.toString() 
            if(registro.numeroPagoTotal) registro.numeroPagoNombre += '/' + registro.numeroPagoTotal.toString();
            else registro.numeroPagoNombre = '';
            registro.meta.primaNeta = registro.primaNeta;
            registro.meta.totalGastos = registro.totalGastos;
            registro.meta.totalImpuestos = registro.totalImpuestos;
            registro.meta.totalFraccionamiento = registro.totalFraccionamiento;
            registro.meta.primaTotal = registro.primaTotal;
            registro.meta.monto = registro.primaTotal;
            registro.meta.MonedaId = registro.MonedaId;

            if(registro.formaCobro) {
                var index = this.indexOf(this.apiURLs.formasCobro, 'id', json.formaCobro);
                if(index != -1) registro.nombreFormaPago = this.apiURLs.formasCobro[index].nombre;
            }

            if(registro.distribucionComision) {
                registro.distribucionComisionArray = JSON.parse(registro.distribucionComision);
            }

            if(registro.multiplesFormasPago) {
                registro.multiplesFormasPagoArray = JSON.parse(registro.multiplesFormasPago);
            }

            if(registro.etapa) {
                var index = this.indexOf(this.apiURLs.etapasCobros, 'id', json.etapa);
                if(index != -1) registro.nombreEtapa = this.apiURLs.etapasCobros[index].nombre;
            }

            if(json.fechaIngreso) {
                registro.fechaIngreso = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaIngresoFormato = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaIngresoDate = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaPago) {
                registro.fechaPago = this.convertirFecha(json.fechaPago, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaPagoFormato = this.convertirFecha(json.fechaPago, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaPagoDate = this.convertirFecha(json.fechaPago, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaAplicacion) {
                registro.fechaAplicacion = this.convertirFecha(json.fechaAplicacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaAplicacionFormato = this.convertirFecha(json.fechaAplicacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaAplicacionDate = this.convertirFecha(json.fechaAplicacion, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaCambio) {
                registro.fechaCambio = this.convertirFecha(json.fechaCambio, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaCambioFormato = this.convertirFechaATimezone(json.fechaCambio, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.fechaCambioDate = this.convertirFecha(json.fechaCambio, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaAnulacion) {
                registro.fechaAnulacion = this.convertirFecha(json.fechaAnulacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaAnulacionFormato = this.convertirFechaATimezone(json.fechaAnulacion, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.fechaAnulacionDate = this.convertirFecha(json.fechaAnulacion, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaLiquidacion) {
                registro.fechaLiquidacion = this.convertirFecha(json.fechaLiquidacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaLiquidacionFormato = this.convertirFechaATimezone(json.fechaLiquidacion, 'DD/MM/YYYY', this.apiURLs.defaultTimezone);
                registro.fechaLiquidacionDate = this.convertirFecha(json.fechaLiquidacion, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaEmision) {
                registro.fechaEmision = this.convertirFecha(json.fechaEmision, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaEmisionFormato = this.convertirFecha(json.fechaEmision, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaEmisionDate = this.convertirFecha(json.fechaEmision, 'YYYY-MM-DD', 'date');
                registro.diasAtraso = moment(new Date()).diff(registro.fechaEmisionDate, 'days');
                if(registro.diasAtraso < 0) registro.diasAtraso = 0;
            }

            if(json.Poliza) {
                var poliza = this.polizaDeJSON(json.Poliza);
                registro.poliza = poliza;
                if(registro.poliza.numeroPagos && !registro.numeroPagoTotal) {
                    registro.numeroPagoNombre += ' / ' + registro.poliza.numeroPagos;
                }
                registro.nombreCliente = registro.poliza.nombreCliente;
                registro.nombreAgrupador = registro.poliza.nombreAgrupador;
                registro.nombreProducto = registro.poliza.nombreProducto;
                registro.numeroPoliza = registro.poliza.numero;
            }

            if(json.Moneda) {
                var moneda = this.monedaDeJSON(json.Moneda);
                registro.moneda = moneda;
                registro.meta.moneda = moneda;
            }

            if(json.Pagador) {
                var pagador = this.usuarioDeJSON(json.Pagador);
                registro.pagador = pagador;
                registro.nombrePagador = pagador.nombreCompleto;
            }

            if(json.AgenteComision) {
                var agenteComision = this.usuarioDeJSON(json.AgenteComision);
                registro.agenteComision = agenteComision;
            }

            if(json.Cobrador) {
                var cobrador = this.cobradorDeJSON(json.Cobrador);
                registro.cobrador = cobrador;
            }

            if(json.PlanillaCobro) {
                var planilla = this.planillaCobroDeJSON(json.PlanillaCobro);
                registro.planilla = planilla;
            }

            if(json.Usuario) {
                var usuario = this.usuarioDeJSON(json.Usuario);
                registro.usuario = usuario;
                registro.nombreUsuario = usuario.nombreCompleto;
            }

            if(json.UsuarioAplicador) {
                var usuarioAplicador = this.usuarioDeJSON(json.UsuarioAplicador);
                registro.usuarioAplicador = usuarioAplicador;
                registro.nombreUsuarioAplicador = usuarioAplicador.nombreCompleto;
            }

            if(json.UsuarioLiquidador) {
                var usuarioLiquidador = this.usuarioDeJSON(json.UsuarioLiquidador);
                registro.usuarioLiquidador = usuarioLiquidador;
                registro.nombreUsuarioLiquidador = usuarioLiquidador.nombreCompleto;
            }

            if(json.UsuarioAnulacion) {
                var usuarioAnulacion = this.usuarioDeJSON(json.UsuarioAnulacion);
                registro.usuarioAnulacion = usuarioAnulacion;
                registro.nombreUsuarioAnulacion = usuarioAnulacion.nombreCompleto;
            }

            if(json.SeguimientoCobros) {
                registro.seguimientos = [];
                for (let i = 0; i < json.SeguimientoCobros.length; i++) {
                    const element = json.SeguimientoCobros[i];
                    var seguimiento = this.seguimientoCobroDeJSON(element);
                    registro.seguimientos.push(seguimiento);
                }
            }

            if(json.GastoProductoDocumentoCobros) {
                registro.gastos = [];
                for (let i = 0; i < json.GastoProductoDocumentoCobros.length; i++) {
                    const element = json.GastoProductoDocumentoCobros[i];
                    var gasto = this.gastoProductoDocumentoCobroDeJSON(element);
                    registro.gastos.push(gasto);
                }
            }

            if(json.ImpuestoDocumentoCobros) {
                registro.impuestos = [];
                for (let i = 0; i < json.ImpuestoDocumentoCobros.length; i++) {
                    const element = json.ImpuestoDocumentoCobros[i];
                    var impuesto = this.impuestoDocumentoCobroDeJSON(element);
                    registro.impuestos.push(impuesto);
                }
            }

            if(json.CertificadoDocumentoCobros) {
                registro.certificados = [];
                registro.idsCertificados = [];
                registro.nombresCertificados = '';
                for (let i = 0; i < json.CertificadoDocumentoCobros.length; i++) {
                    const element = json.CertificadoDocumentoCobros[i];
                    if(element.Certificado) {
                        var certificado = this.certificadoDeJSON(element.Certificado);
                        registro.certificados.push(certificado);
                        registro.idsCertificados.push(element.CertificadoId);
                        if(registro.nombresCertificados != '') registro.nombresCertificados += ', ';
                        registro.nombresCertificados += certificado.nombreNumeroCertificado;
                    }
                }
            }

            if(json.AplicacionDocumentoCreditos) {
                registro.aplicacionesCreditos = [];
                for (let i = 0; i < json.AplicacionDocumentoCreditos.length; i++) {
                    const element = json.AplicacionDocumentoCreditos[i];
                    var aplicacion = this.aplicacionDocumentoCreditoDeJSON(element);
                    registro.aplicacionesCreditos.push(aplicacion);
                }
            }

            if(json.pagadoresRelacionados && json.pagadoresRelacionados.length > 0) {
                registro.textoPagadoresRelacionados = 'Pagador en certificados: ';
                let i = 0;
                for (const el of json.pagadoresRelacionados) {
                    if(i > 0) registro.textoPagadoresRelacionados += ', ';
                    registro.textoPagadoresRelacionados += el.numero;
                    i++;
                }
            }

            return registro;
        } catch(error) {
            console.error(error);
            return null;
        }
    }

    public documentoCreditoDeJSON = (json: any): DocumentoCredito => {
        if(!json) return null;
        var registro = new DocumentoCredito(
            json.id,
            json.numeroRequisito,
            json.numeroRecibo,
            json.etapa,
            json.fechaIngreso,
            json.fechaEmision,
            json.fechaExpiracionIVA,
            json.primaNeta,
            json.totalGastos,
            json.totalImpuestos,
            json.totalFraccionamiento,
            json.totalEmision,
            json.creditoTotal,
            json.creditoUtilizado,
            json.creditoRestante,
            json.razonCredito,
            json.observaciones,
            json.formaPago,
            json.banco,
            json.numeroCheque,
            json.fechaCredito,
            json.pagado,
            json.eliminado,
            json.PagadorId,
            json.UsuarioId,
            json.PolizaId,
            json.MonedaId,
            json.EmpresaId,
        );
        registro.metaCreditoTotal.monto = registro.creditoTotal;
        registro.metaCreditoTotal.MonedaId = registro.MonedaId;
        registro.metaCreditoRestante.monto = registro.creditoRestante;
        registro.metaCreditoRestante.MonedaId = registro.MonedaId;
        registro.metaCreditoUtilizado.monto = registro.creditoUtilizado;
        registro.metaCreditoUtilizado.MonedaId = registro.MonedaId;

        if(json.fechaIngreso) {
            registro.fechaIngreso = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaIngresoFormato = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaIngresoDate = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaExpiracionIVA) {
            registro.fechaExpiracionIVA = this.convertirFecha(json.fechaExpiracionIVA, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaExpiracionIVAFormato = this.convertirFecha(json.fechaExpiracionIVA, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaExpiracionIVADate = this.convertirFecha(json.fechaExpiracionIVA, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaEmision) {
            registro.fechaEmision = this.convertirFecha(json.fechaEmision, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaEmisionFormato = this.convertirFecha(json.fechaEmision, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaEmisionDate = this.convertirFecha(json.fechaEmision, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaCredito) {
            registro.fechaCredito = this.convertirFecha(json.fechaCredito, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCreditoFormato = this.convertirFecha(json.fechaCredito, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaCreditoDate = this.convertirFecha(json.fechaCredito, 'YYYY-MM-DD', 'date');
        }

        if(json.Poliza) {
            var poliza = this.polizaDeJSON(json.Poliza);
            registro.poliza = poliza;
        }

        if(json.Moneda) {
            var moneda = this.monedaDeJSON(json.Moneda);
            registro.moneda = moneda;
            registro.metaCreditoRestante.moneda = moneda;
            registro.metaCreditoTotal.moneda = moneda;
            registro.metaCreditoUtilizado.moneda = moneda;
        }

        if(json.Pagador) {
            var pagador = this.usuarioDeJSON(json.Pagador);
            registro.pagador = pagador;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
        }

        if(registro.formaPago) {
            var index = this.indexOf(this.apiURLs.formasCobro, 'id', json.formaCobro);
            if(index != -1) registro.nombreFormaPago = this.apiURLs.formasCobro[index].nombre;
        }

        if(registro.etapa) {
            var index = this.indexOf(this.apiURLs.etapasCreditos, 'id', json.etapa);
            if(index != -1) registro.nombreEtapa = this.apiURLs.etapasCreditos[index].nombre;
        }

        if(json.GastoProductoDocumentoCreditos) {
            registro.gastos = [];
            for (let i = 0; i < json.GastoProductoDocumentoCreditos.length; i++) {
                const element = json.GastoProductoDocumentoCreditos[i];
                var gasto = this.gastoProductoDocumentoCreditoDeJSON(element);
                registro.gastos.push(gasto);
            }
        }

        if(json.ImpuestoDocumentoCreditos) {
            registro.impuestos = [];
            for (let i = 0; i < json.ImpuestoDocumentoCreditos.length; i++) {
                const element = json.ImpuestoDocumentoCreditos[i];
                var impuesto = this.impuestoDocumentoCreditoDeJSON(element);
                registro.impuestos.push(impuesto);
            }
        }

        if(json.AplicacionDocumentoCreditos) {
            registro.aplicacionesCreditos = [];
            for (let i = 0; i < json.AplicacionDocumentoCreditos.length; i++) {
                const element = json.AplicacionDocumentoCreditos[i];
                var aplicacion = this.aplicacionDocumentoCreditoDeJSON(element);
                registro.aplicacionesCreditos.push(aplicacion);
            }
        }

        // Estado
        let estadoColor = { nombre: null, color: '#000000' };
        if(
            (registro.creditoRestante || registro.creditoRestante == 0) &&
            (registro.creditoUtilizado || registro.creditoUtilizado == 0)
        ) {
            if(registro.eliminado) estadoColor = { nombre: 'Anulado', color: 'red' };
            else if(registro.creditoUtilizado == 0) estadoColor = { nombre: 'Pendiente', color: 'green' };
            else if(registro.creditoRestante > 0) estadoColor = { nombre: 'Aplicado parcial', color: 'blue' };
            else if(registro.pagado || registro.creditoRestante <= 0) estadoColor = { nombre: 'Aplicado', color: '#000000' };
        }
        registro.nombreEstado = estadoColor.nombre;
        registro.colorEstado = estadoColor.color;



        return registro;
    }

    public aplicacionDocumentoCreditoDeJSON = (json: any): AplicacionDocumentoCredito => {
        if(!json) return null;
        var registro = new AplicacionDocumentoCredito(
            json.id,
            json.tipo,
            json.fechaAplicacion,
            json.monto,
            json.numeroCheque,
            json.banco,
            json.observaciones,
            json.DocumentoCobroId,
            json.DocumentoCreditoId,
            json.UsuarioId,
            json.MonedaId,
            json.EmpresaId,
            json.PlanillaLiquidacionId,
        );
        registro.meta.monto = registro.monto;
        registro.meta.MonedaId = registro.MonedaId;
        registro.meta.DocumentoCobroId = registro.DocumentoCobroId;
        registro.meta.DocumentoCreditoId = registro.DocumentoCreditoId;
        registro.meta.id = registro.id;

        if(registro.tipo == 'credito') registro.tipoNombre = 'Aplicación de crédito';
        else if(registro.tipo == 'cheque') registro.tipoNombre = 'Cheque de devolución';

        if(json.fechaAplicacion) {
            registro.fechaAplicacion = this.convertirFecha(json.fechaAplicacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAplicacionFormato = this.convertirFecha(json.fechaAplicacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAplicacionDate = this.convertirFecha(json.fechaAplicacion, 'YYYY-MM-DD', 'date');
        }

        if(json.Moneda) {
            var moneda = this.monedaDeJSON(json.Moneda);
            registro.moneda = moneda;
            registro.meta.moneda = moneda;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(json.DocumentoCredito) {
            var documentoCredito = this.documentoCreditoDeJSON(json.DocumentoCredito);
            registro.documentoCredito = documentoCredito;
        }

        if(json.DocumentoCobro) {
            var documentoCobro = this.documentoCobroDeJSON(json.DocumentoCobro);
            registro.documentoCobro = documentoCobro;
        }

        return registro;
    }

    public certificadoDeJSON = (json: any): Certificado => {
        try {
            if(!json) return null;
            var registro = new Certificado(
                json.id,
                json.nombre,
                json.estado,
                json.fechaAlta,
                json.fechaBaja,
                json.fechaReporteAlta,
                json.fechaReporteBaja,
                json.fechaVigenciaInicio,
                json.fechaVigenciaFin,
                json.sumaAsegurada,
                json.primaFacturada,
                json.primaAnualizada,
                json.primaEnDeposito,
                json.tipoCambio,
                json.porcentajeTasa,
                json.pagador,
                json.observaciones,
                json.observaciones2,
                json.observaciones3,
                json.numero,
                json.numeroInt,
                json.exclusionTemporal,
                json.fechaExclusionTemporal,
                json.exclusionPermanente,
                json.cuentaMonetaria,
                json.clausulasGarantia,
                //relaciones
                json.PolizaId,
                json.TipoCertificadoId,
                json.PlantillaRamoId,
                json.EmpresaId,
                json.RazonPerdidaId,

                json.TipoVehiculoId,
                json.MarcaVehiculoId,
                json.LineaVehiculoId,

                json.tieneDispositivoSeguridad,
                json.dispositivoSeguridad,
                json.condicion,
                json.fechaInicioDispositivoSeguridad,
                json.fechaFinDispositivoSeguridad,
                json.referenciaDispositivoSeguridad,
            );

            registro.totalPrimaNetaDeclaraciones = json.totalPrimaNetaDeclaraciones;
            if(!registro.primaEnDeposito) registro.primaEnDeposito = 0;
            if(!registro.totalPrimaNetaDeclaraciones) registro.totalPrimaNetaDeclaraciones = 0;
            registro.primaAFavorAsegurado = registro.primaEnDeposito - registro.totalPrimaNetaDeclaraciones;

            registro.nombreCertificado = 'Certificado no. ' + registro.numero;
            if(registro.nombre) registro.nombreCertificado += ' - ' + registro.nombre;

            if(registro.nombre) {
                registro.nombreCertificadoResumen = registro.nombre;
                if(registro.nombreCertificadoResumen.length > 35) registro.nombreCertificadoResumen = registro.nombre.substr(0, 35) + '...';
            }

            registro.nombreNumeroCertificado = '';
            if(registro.numero) registro.nombreNumeroCertificado += registro.numero;
            if(registro.nombre && registro.nombre.trim() != '') {
                if(registro.nombreNumeroCertificado.trim() != '') registro.nombreNumeroCertificado += ' - ';
                registro.nombreNumeroCertificado += registro.nombre;
            }

            if(registro.estado) {
                var index = this.indexOf(this.apiURLs.estadosCertificados, 'id', registro.estado);
                if(index != -1) registro.nombreEstado = this.apiURLs.estadosCertificados[index].nombre;
            }

            if(json.fechaInicioDispositivoSeguridad) {
                registro.fechaInicioDispositivoSeguridad = this.convertirFecha(json.fechaInicioDispositivoSeguridad, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaInicioDispositivoSeguridadFormato = this.convertirFecha(json.fechaInicioDispositivoSeguridad, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaInicioDispositivoSeguridadDate = this.convertirFecha(json.fechaInicioDispositivoSeguridad, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaFinDispositivoSeguridad) {
                registro.fechaFinDispositivoSeguridad = this.convertirFecha(json.fechaFinDispositivoSeguridad, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaFinDispositivoSeguridadFormato = this.convertirFecha(json.fechaFinDispositivoSeguridad, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaFinDispositivoSeguridadDate = this.convertirFecha(json.fechaFinDispositivoSeguridad, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaAlta) {
                registro.fechaAlta = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaAltaFormato = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaAltaDate = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaBaja) {
                registro.fechaBaja = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaBajaFormato = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaBajaDate = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaReporteAlta) {
                registro.fechaReporteAlta = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaReporteAltaFormato = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaReporteAltaDate = this.convertirFecha(json.fechaReporteAlta, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaReporteBaja) {
                registro.fechaReporteBaja = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaReporteBajaFormato = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaReporteBajaDate = this.convertirFecha(json.fechaReporteBaja, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaVigenciaInicio) {
                registro.fechaVigenciaInicio = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaVigenciaInicioFormato = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaVigenciaInicioDate = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaVigenciaFin) {
                registro.fechaVigenciaFin = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaVigenciaFinFormato = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaVigenciaFinDate = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'date');
            }

            if (json.fechaExclusionTemporal) {
                registro.fechaExclusionTemporal = this.convertirFecha(json.fechaExclusionTemporal,'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaExclusionTemporalFormato = this.convertirFecha(json.fechaExclusionTemporal,'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaExclusionTemporalDate = this.convertirFecha(json.fechaExclusionTemporal, 'YYYY-MM-DD', 'date');
            }

            if (json.fechaVigenciaMinima) {
                registro.fechaVigenciaMinima = this.convertirFecha(json.fechaVigenciaMinima,'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaVigenciaMinimaFormato = this.convertirFecha(json.fechaVigenciaMinima,'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaVigenciaMinimaDate = this.convertirFecha(json.fechaVigenciaMinima, 'YYYY-MM-DD', 'date');
            }
            else {
                registro.fechaVigenciaMinima = this.convertirFecha(json.fechaVigenciaInicio,'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaVigenciaMinimaFormato = this.convertirFecha(json.fechaVigenciaInicio,'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaVigenciaMinimaDate = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'date');
            }

            if(json.TipoCertificado) {
                var tipo = this.tipoCertificadoDeJSON(json.TipoCertificado);
                registro.tipoCertificado = tipo;
                registro.nombreTipoCertificado = tipo.nombre;
            }

            if(json.PlantillaRamo) {
                var plantilla = this.plantillaRamoDeJSON(json.PlantillaRamo);
                registro.plantillaRamo = plantilla;
                registro.nombrePlantillaRamo = plantilla.nombre;
            }

            if(json.Poliza) {
                var poliza = this.polizaDeJSON(json.Poliza);
                registro.poliza = poliza;
                registro.numeroPoliza = poliza.numero;
            }

            if(json.ValorCertificados) {
                registro.valores = [];
                for (let i = 0; i < json.ValorCertificados.length; i++) {
                    const element = json.ValorCertificados[i];
                    var valor = this.valorCertificadoDeJSON(element);
                    registro.valores.push(valor);
                }
            }

            if(json.IncisoCertificados) {
                registro.incisos = [];
                for (let i = 0; i < json.IncisoCertificados.length; i++) {
                    const element = json.IncisoCertificados[i];
                    var inciso = this.incisoCertificadoDeJSON(element);
                    registro.incisos.push(inciso);
                }
            }

            if(json.IncisoHistoricoCertificados) {
                registro.incisosHistoricos = [];
                for (let i = 0; i < json.IncisoHistoricoCertificados.length; i++) {
                    const element = json.IncisoHistoricoCertificados[i];
                    let incisoHistorico = this.incisoHistoricoCertificadoDeJSON(element);
                    registro.incisosHistoricos.push(incisoHistorico);
                }
            }

            if(json.DeducibleMonedaCertificados) {
                registro.deduciblesMoneda = [];
                registro.idsDeduciblesMoneda = [];
                for (let i = 0; i < json.DeducibleMonedaCertificados.length; i++) {
                    const element = json.DeducibleMonedaCertificados[i];
                    var deducible = this.deducibleMonedaCertificadoDeJSON(element);
                    registro.deduciblesMoneda.push(deducible);
                    registro.idsDeduciblesMoneda.push(deducible.id);
                }
            }

            if(json.Declaracions) {
                registro.declaraciones = [];
                for (let i = 0; i < json.Declaracions.length; i++) {
                    const element = json.Declaracions[i];
                    var declaracion = this.declaracionDeJSON(element);
                    registro.declaraciones.push(declaracion);
                }
            }

            if(json.BeneficiarioDependienteCertificados) {
                registro.beneficiarios = [];
                registro.dependientes = [];
                for (let i = 0; i < json.BeneficiarioDependienteCertificados.length; i++) {
                    const element = json.BeneficiarioDependienteCertificados[i];
                    var beneficiario = this.beneficiarioDependienteCertificadoDeJSON(element);
                    if(beneficiario.tipo == 'beneficiario' || beneficiario.tipo == 'beneficiario-dependiente') registro.beneficiarios.push(beneficiario);
                    if(beneficiario.tipo == 'dependiente' || beneficiario.tipo == 'beneficiario-dependiente') registro.dependientes.push(beneficiario);
                }
            }

            if(json.PagadorCertificados) {
                registro.pagadores = [];
                registro.idsPagadores = [];
                registro.nombresPagadores = '';
                for (let i = 0; i < json.PagadorCertificados.length; i++) {
                    const element = json.PagadorCertificados[i];
                    var pagador = this.pagadorCertificadoDeJSON(element);
                    registro.pagadores.push(pagador);
                    registro.idsPagadores.push(element.ClienteId);
                    if(registro.nombresPagadores.trim() != '') registro.nombresPagadores += ', ';
                    registro.nombresPagadores += (pagador.cliente ? pagador.cliente.nombreCompleto : '') + '(' + pagador.porcentaje + '%)';
                }
            }

            if(json.RevisionPoliza) {
                var revision = this.revisionPolizaDeJSON(json.RevisionPoliza);
                registro.revision = revision;
            }

            return registro;
        } catch(error) {
            console.error(error);
            return null;
        }
    }

    public polizaDeJSON = (json: any): Poliza => {
        if(!json) return null;
        var registro = new Poliza(
            json.id,
            json.numero,
            json.numeroEmision,
            json.aniosVigencia,
            json.notasAdicionales,
            json.tipo,
            json.resguardo,
            json.fechaIngreso,
            json.fechaResguardo,
            json.fechaVigenciaInicio,
            json.fechaVigenciaFin,
            json.horasVigencia,
            json.caracter,
            json.numeroPagos,
            json.frecuenciaPago,
            json.formaCobro,
            json.pagosIguales,
            json.estado,
            json.borrador,
            json.cobrosGenerados,
            json.sumaAsegurada,
            json.primaNeta,
            json.tipoCambio,
            json.ultimaVisualizacion,
            json.estadoControlCalidad,
            json.maximoVitalicio,
            json.polizaRegional,
            json.conDevolucion,
            json.pagoDirecto,
            json.polizaVidaTermino,
            json.fechaFinTerminos,
            json.facturasAnticipadas,
            json.AseguradoraId,
            json.AgrupadorId,
            json.MonedaId,
            json.RamoId,
            json.ProductoId,
            json.UsuarioId,
            json.AgenteId,
            json.ContactoAtencionId,
            json.VendedorId,
            json.EjecutivoId,
            json.ClienteId,
            json.CobradorId,
            json.RazonPerdidaId,
            json.EmpresaId,
            json.PolizaVigenteId,
            json.EmpresaProductoraId,
            json.enProcesoRenovacion,
            json.UsuarioRenovadorId,
            json.fechaRenovacion,
        );
        registro.deducibleString = json.deducibleString;

        if(registro.numero) registro.nombrePoliza = 'Póliza no. ' + registro.numero;
        else registro.nombrePoliza = 'Póliza no. ' + registro.id;
        registro.nombrePolizaResumido = registro.nombrePoliza.replace('Póliza no. ', '');

        if(json.ultimaVisualizacion) {
            registro.ultimaVisualizacion = this.convertirFecha(json.ultimaVisualizacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.ultimaVisualizacionFormato = this.convertirFecha(json.ultimaVisualizacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
        }

        if(json.totalPagadoReclamos != null) registro.totalPagadoReclamos = json.totalPagadoReclamos;

        if(json.fechaIngreso) {
            registro.fechaIngreso = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaIngresoFormato = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaIngresoDate = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'date');
            registro.diasIngreso = moment(new Date()).diff(registro.fechaIngreso, 'days');
            if(registro.diasIngreso < 0) registro.diasIngreso = 0;
        }

        if(json.fechaResguardo) {
            registro.fechaResguardo = this.convertirFecha(json.fechaResguardo, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaResguardoFormato = this.convertirFecha(json.fechaResguardo, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaResguardoDate = this.convertirFecha(json.fechaResguardo, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaFinTerminos) {
            registro.fechaFinTerminos = this.convertirFecha(json.fechaFinTerminos, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFinTerminosFormato = this.convertirFecha(json.fechaFinTerminos, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFinTerminosDate = this.convertirFecha(json.fechaFinTerminos, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaVigenciaInicio) {
            registro.fechaVigenciaInicio = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaInicioFormato = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaInicioDate = this.convertirFecha(json.fechaVigenciaInicio, 'YYYY-MM-DD', 'date');
            registro.fechaVigenciaInicioHace2AniosDate = moment(json.fechaVigenciaInicio).subtract(2, 'years').toDate();
        }

        if(json.fechaVigenciaFin) {
            registro.fechaVigenciaFin = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaVigenciaFinFormato = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaVigenciaFinDate = this.convertirFecha(json.fechaVigenciaFin, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaRenovacion) {
            registro.fechaRenovacion = this.convertirFecha(json.fechaRenovacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaRenovacionFormato = this.convertirFechaATimezone(json.fechaRenovacion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaRenovacionDate = this.convertirFecha(json.fechaRenovacion, 'YYYY-MM-DD', 'date');
        }

        if(registro.frecuenciaPago) {
            var indexFrecuencia = this.indexOf(this.apiURLs.frecuenciasPagos, 'id', registro.frecuenciaPago);
            if(indexFrecuencia != -1) registro.nombreFrecuenciaPagos = this.apiURLs.frecuenciasPagos[indexFrecuencia].nombre;
        }

        if(registro.estado) {
            var index = this.indexOf(this.apiURLs.estadosPolizas, 'id', registro.estado);
            if(index != -1) registro.nombreEstado = this.apiURLs.estadosPolizas[index].nombre;
        }

        if(registro.tipo) {
            var index = this.indexOf(this.apiURLs.tiposPolizas, 'id', registro.tipo);
            if(index != -1) registro.nombreTipo = this.apiURLs.tiposPolizas[index].nombre;
        }

        if(registro.estadoControlCalidad) {
            var index = this.indexOf(this.apiURLs.estadosControlCalidad, 'id', registro.estadoControlCalidad);
            if(index != -1) registro.estadoControlCalidadNombre = this.apiURLs.estadosControlCalidad[index].nombre;
        }

        if(json.Aseguradora) {
            var aseguradora = this.aseguradoraDeJSON(json.Aseguradora);
            registro.aseguradora = aseguradora;
            registro.nombreAseguradora = aseguradora.nombre;
        }

        if(json.Agrupador) {
            var agrupador = this.agrupadorDeJSON(json.Agrupador);
            registro.agrupador = agrupador;
            registro.nombreAgrupador = agrupador.nombre;
        }

        if(json.Ramo) {
            var ramo = this.ramoDeJSON(json.Ramo);
            registro.ramo = ramo;
            registro.nombreRamo = ramo.nombre;
        }

        if(json.Moneda) {
            var moneda = this.monedaDeJSON(json.Moneda);
            registro.moneda = moneda;
        }

        if(json.RazonPerdida) {
            var razonPerdida = this.razonPerdidaDeJSON(json.RazonPerdida);
            registro.razonPerdida = razonPerdida;
        }

        if(json.Producto) {
            var producto = this.productoDeJSON(json.Producto);
            registro.producto = producto;
            registro.nombreProducto = producto.nombre;
        }

        if(json.UsuarioRenovador) {
            var usuarioRenovador = this.usuarioDeJSON(json.UsuarioRenovador);
            registro.usuarioRenovador = usuarioRenovador;
            registro.nombreUsuarioRenovador = usuarioRenovador.nombreCompleto;
        }

        if(json.Agente) {
            var agente = this.usuarioDeJSON(json.Agente);
            registro.agente = agente;
            registro.nombreAgente = agente.nombreCompleto;
        }

        if(json.ContactoAtencion) {
            var contactoAtencion = this.usuarioDeJSON(json.ContactoAtencion);
            registro.contactoAtencion = contactoAtencion;
            registro.nombreContactoAtencion = contactoAtencion.nombreCompleto;
        }

        if(json.Ejecutivo) {
            var ejecutivo = this.usuarioDeJSON(json.Ejecutivo);
            registro.ejecutivo = ejecutivo;
            registro.nombreEjecutivo = ejecutivo.nombreCompleto;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(json.Vendedor) {
            var vendedor = this.usuarioDeJSON(json.Vendedor);
            registro.vendedor = vendedor;
            registro.nombreVendedor = vendedor.nombreCompleto;
        }

        if(json.Cliente) {
            var cliente = this.usuarioDeJSON(json.Cliente);
            registro.cliente = cliente;
            registro.nombreCliente = cliente.nombreCompleto;
            registro.nombreAgrupador = cliente.nombreAgrupador;
        }

        if(json.EmpresaProductora) {
            var empresaProductora = this.empresaDeJSON(json.EmpresaProductora);
            registro.empresaProductora = empresaProductora;
            registro.nombreEmpresaProductora = empresaProductora.nombre;
        }

        if(json.Cobrador) {
            var cobrador = this.cobradorDeJSON(json.Cobrador);
            registro.cobrador = cobrador;
            registro.nombreCobrador = cobrador.nombreCompleto;
        }

        if(json.RevisionPoliza) {
            var revision = this.revisionPolizaDeJSON(json.RevisionPoliza);
            registro.revision = revision;
        }

        if(json.DocumentoPolizas) {
            registro.documentos = [];
            for (let i = 0; i < json.DocumentoPolizas.length; i++) {
                const element = json.DocumentoPolizas[i];
                var documento = this.documentoPolizaDeJSON(element);
                registro.documentos.push(documento);
            }
        }

        if(json.DocumentoCobros) {
            registro.documentosCobros = [];
            for (let i = 0; i < json.DocumentoCobros.length; i++) {
                const element = json.DocumentoCobros[i];
                var documentoCobro = this.documentoCobroDeJSON(element);
                registro.documentosCobros.push(documentoCobro);
            }
        }

        if(json.AclaracionPolizas) {
            registro.aclaraciones = [];
            for (let i = 0; i < json.AclaracionPolizas.length; i++) {
                const element = json.AclaracionPolizas[i];
                var aclaracion = this.aclaracionPolizaDeJSON(element);
                registro.aclaraciones.push(aclaracion);
            }
        }

        if(json.CambioEstadoPolizas) {
            registro.cambiosEstado = [];
            for (let i = 0; i < json.CambioEstadoPolizas.length; i++) {
                const element = json.CambioEstadoPolizas[i];
                var cambioEstado = this.cambioEstadoPolizaDeJSON(element);
                registro.cambiosEstado.push(cambioEstado);
            }
        }

        if(json.Certificados) {
            registro.certificados = [];
            for (let i = 0; i < json.Certificados.length; i++) {
                const element = json.Certificados[i];
                var certificado = this.certificadoDeJSON(element);
                registro.certificados.push(certificado);
            }
        }

        if(json.PorcentajeCoaseguros) {
            registro.porcentajesCoaseguros = [];
            for (let i = 0; i < json.PorcentajeCoaseguros.length; i++) {
                const element = json.PorcentajeCoaseguros[i];
                var porcentajeCoaseguros = this.porcentajeCoasegurosDeJSON(element);
                registro.porcentajesCoaseguros.push(porcentajeCoaseguros);
            }
        }

        if(json.PagadorPolizas) {
            registro.pagadores = [];
            for (let i = 0; i < json.PagadorPolizas.length; i++) {
                const element = json.PagadorPolizas[i];
                var pagador = this.pagadorPolizaDeJSON(element);
                registro.pagadores.push(pagador);
            }
        }

        if(json.dependientesRelacionados && json.dependientesRelacionados.length > 0) {
            registro.textoDependientesRelacionados = 'Dep. en certs.: ';
            let i = 0;
            for (const el of json.dependientesRelacionados) {
                if(i > 0) registro.textoDependientesRelacionados += ', ';
                registro.textoDependientesRelacionados += el.numero;
                i++;
            }
        }

        return registro;
    }

    public prospectoDeJSON = (json: any): Prospecto => {
        if(!json) return null;
        var registro = new Prospecto(
            json.id,
            json.nombre,
            json.apellido,
            json.etapa,
            json.fechaIngreso,
            json.fechaContacto,
            json.correo,
            json.telefono1,
            json.telefono2,
            json.direccion,
            json.empresa,
            json.primaNetaCalculada,
            json.esCliente,
            json.clienteCreado,
            json.pospuesto,
            json.marca,
            json.linea,
            json.anioVehiculo,
            json.valorVehiculo,
            json.usoVehiculo,
            json.comentarios,
            json.tipoProspecto,
            json.origen,
            json.VendedorId,
            json.EjecutivoId,
            json.Ejecutivo2Id,
            json.Ejecutivo3Id,
            json.ClienteId,
            json.RazonPerdidaId,
            json.MonedaId,
            json.EmpresaId,
        );

        var nombres = '', apellidos = '';
        if(registro.nombre) nombres = registro.nombre;
        if(registro.apellido) apellidos = registro.apellido;
        registro.nombreCompleto = nombres + ' ' + apellidos;
        registro.nombreCompleto = registro.nombreCompleto.trim();

        if(json.fechaContacto) {
            registro.fechaContacto = this.convertirFecha(json.fechaContacto, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaContactoFormato = this.convertirFecha(json.fechaContacto, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaContactoDate = this.convertirFecha(json.fechaContacto, 'YYYY-MM-DD', 'date');
            if(registro.pospuesto) {
                registro.metaContactar = registro.fechaContactoFormato;
            }
        }

        if(json.RamoProspectos) {
            registro.ramos = [];
            registro.idsRamos = [];
            registro.nombreRamos = '';
            for (let i = 0; i < json.RamoProspectos.length; i++) {
                const element = json.RamoProspectos[i];
                if(element.Ramo) {
                    var ramo = this.ramoDeJSON(element.Ramo);
                    registro.ramos.push(ramo);
                    registro.idsRamos.push(element.RamoId);
                    if(registro.nombreRamos != '') registro.nombreRamos += ', ';
                    registro.nombreRamos += ramo.nombre;
                }
            }
        }

        if(json.RazonPerdida) {
            var razonPerdida = this.razonPerdidaDeJSON(json.RazonPerdida);
            registro.razonPerdida = razonPerdida;
            registro.nombreRazonPerdida = razonPerdida.titulo;
        }

        if(json.Vendedor) {
            var vendedor = this.usuarioDeJSON(json.Vendedor);
            registro.vendedor = vendedor;
        }

        if(json.Ejecutivo) {
            var ejecutivo = this.usuarioDeJSON(json.Ejecutivo);
            registro.ejecutivo = ejecutivo;
            registro.nombreEjecutivo = ejecutivo.nombreCompleto;
        }

        if(json.Cliente) {
            var cliente = this.usuarioDeJSON(json.Cliente);
            registro.cliente = cliente;
            registro.nombreCliente = cliente.nombreCompleto;
        }

        if(json.Moneda) {
            var moneda = this.monedaDeJSON(json.Moneda);
            registro.moneda = moneda;
        }

        if(json.SeguimientosProspectos) {
          registro.SeguimientosProspectos = [];
          for (let i = 0; i < json.SeguimientosProspectos.length; i++) {
              const element = json.SeguimientosProspectos[i];
              var seguimiento = this.seguimientoProspectoDeJSON(element);
              registro.SeguimientosProspectos.push(seguimiento);
          }
      }

        return registro;
    }

    public razonPerdidaDeJSON = (json: any): RazonPerdida => {
        if(!json) return null;
        var registro = new RazonPerdida(
            json.id,
            json.titulo,
            json.descripcion,
            json.activo,
            json.EmpresaId,
        );

        return registro;
    }

    public ocupacionDeJSON = (json: any): Ocupacion => {
        if(!json) return null;
        var registro = new Ocupacion(
            json.id,
            json.titulo,
            json.descripcion,
            json.activo,
            json.riesgo,
            json.EmpresaId,
        );

        return registro;
    }

    public operacionAccesoDeJSON = (json: any): OperacionAcceso => {
        if(!json) return null;
        var registro = new OperacionAcceso(
            json.id,
            json.nombre,
            json.descripcion,
            json.modulo,
            json.accion,
            json.activo,
            json.EmpresaId,
        );
        return registro;
    }

    public paisDeJSON = (json: any): Pais => {
        if(!json) return null;
        var registro = new Pais(
            json.id,
            json.nombre,
        );

        return registro;
    }

    public departamentoDeJSON = (json: any): Departamento => {
        if(!json) return null;
        var registro = new Departamento(
            json.id,
            json.nombre,
            json.PaisId,
        );

        if(json.Pai) {
            var pais = this.paisDeJSON(json.Pai);
            registro.pais = pais;
            registro.nombrePais = pais.nombre;
        }

        return registro;
    }

    public municipioDeJSON = (json: any): Municipio => {
        if(!json) return null;
        var registro = new Municipio(
            json.id,
            json.nombre,
            json.PaisId,
            json.DepartamentoId,
        );

        if(json.Zonas){
            registro.zonas = [];
            for (let i = 0; i < json.Zonas.length; i++) {
                const element = json.Zonas[i];
                var zona = this.zonaDeJSON(element);
                registro.zonas.push(zona);
            }
            registro.zonas.sort((a, b) => a.nombre.localeCompare(b.nombre));
        }

        return registro;
    }

    public direccionClienteDeJSON = (json: any): DireccionCliente => {
        if(!json) return null;
        var registro = new DireccionCliente(
            json.id,
            json.tipo,
            json.complemento,
            json.complemento2,
            json.observaciones,
            json.PaisId,
            json.DepartamentoId,
            json.MunicipioId,
            json.ZonaId,
            json.UsuarioId,
            json.inactiva,
        );

        if(json.Pai) {
            registro.pais = this.paisDeJSON(json.Pai);
            registro.nombrePais = registro.pais.nombre;
        }
        if(json.Departamento) {
            registro.departamento = this.departamentoDeJSON(json.Departamento);
            registro.nombreDepartamento = registro.departamento.nombre;
        }
        if(json.Municipio) {
            registro.municipio = this.municipioDeJSON(json.Municipio);
            registro.nombreMunicipio = registro.municipio.nombre;
        }
        if(json.Zona) {
            registro.zona = this.zonaDeJSON(json.Zona);
            registro.nombreZona = registro.zona.nombre;
        }

        if(registro.tipo) {
            var index = this.indexOf(this.apiURLs.tiposDirecciones, 'id', registro.tipo);
            if(index != -1) registro.nombreTipo = this.apiURLs.tiposDirecciones[index].nombre;
        }

        return registro;
    }

    public direccionCertificadoDeJSON = (json: any): DireccionCertificado => {
        if(!json) return null;
        var registro = new DireccionCertificado(
            json.id,
            json.tipo,
            json.complemento,
            json.complemento2,
            json.observaciones,
            json.PaisId,
            json.DepartamentoId,
            json.MunicipioId,
            json.ZonaId,
            json.CertificadoId,
        );

        if(json.Pai) {
            registro.pais = this.paisDeJSON(json.Pai);
            registro.nombrePais = registro.pais.nombre;
        }
        if(json.Departamento) {
            registro.departamento = this.departamentoDeJSON(json.Departamento);
            registro.nombreDepartamento = registro.departamento.nombre;
        }
        if(json.Municipio) {
            registro.municipio = this.municipioDeJSON(json.Municipio);
            registro.nombreMunicipio = registro.municipio.nombre;
        }
        if(json.Zona) {
            registro.zona = this.zonaDeJSON(json.Zona);
            registro.nombreZona = registro.zona.nombre;
        }

        if(registro.tipo) {
            var index = this.indexOf(this.apiURLs.tiposDirecciones, 'id', registro.tipo);
            if(index != -1) registro.nombreTipo = this.apiURLs.tiposDirecciones[index].nombre;
        }

        return registro;
    }

    public marcaVehiculoDeJSON = (json: any): MarcaVehiculo => {
        if(!json) return null;
        var registro = new MarcaVehiculo(
            json.id,
            json.nombre,
            json.EmpresaId,
        );

        if(json.Empresa) {
            var empresa = this.empresaDeJSON(json.Empresa);
            registro.Empresa = empresa;
            registro.nombreEmpresa = empresa.nombre;
        }

        return registro;
    }

    public lineaVehiculoDeJSON = (json: any): LineaVehiculo => {
        if(!json) return null;
        var registro = new LineaVehiculo(
            json.id,
            json.nombre,
            json.EmpresaId,
            json.MarcaVehiculoId,
        );

        return registro;
    }

    public zonaDeJSON = (json: any): Zona => {
        if(!json) return null;
        var registro = new Zona(
            json.id,
            json.nombre,
            json.PaisId,
            json.DepartamentoId,
            json.MunicipioId,
        );

        return registro;
    }

    public tarjetaClienteDeJSON = (json: any): TarjetaCliente => {
        if(!json) return null;
        var registro = new TarjetaCliente(
            json.id,
            json.numero,
            json.vencimiento,
            json.idsPolizas,
            json.aplicaVisacuota,
            json.cantidadVisacuota,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(registro.idsPolizas) {
            registro.idsPolizasArray = JSON.parse(registro.idsPolizas);
        }

        return registro;
    }

    public revisionPolizaDeJSON = (json: any): RevisionPoliza => {
        if(!json) return null;
        var registro = new RevisionPoliza(
            json.id,
            json.fecha,
            json.totalDatosIncorrectos,
            json.totalDatosCorrectos,
            json.totalDatos,
            json.puntuacion,
            json.terminada,
            json.corregida,
            json.observaciones,
            json.PolizaId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(json.Poliza) {
            var poliza = this.polizaDeJSON(json.Poliza);
            registro.poliza = poliza;
        }

        if(json.CorreccionControlCalidads) {
            registro.correcciones = [];
            for (let i = 0; i < json.CorreccionControlCalidads.length; i++) {
                const element = json.CorreccionControlCalidads[i];
                var correccion = this.correccionControlCalidadDeJSON(element);
                registro.correcciones.push(correccion);
            }
        }

        return registro;
    }

    public correccionControlCalidadDeJSON = (json: any): CorreccionControlCalidad => {
        if(!json) return null;
        var registro = new CorreccionControlCalidad(
            json.id,
            json.campo,
            json.valorAnterior,
            json.valorCorregido,
            json.observaciones,
            json.correcto,
            json.PolizaId,
            json.CertificadoId,
            json.RevisionPolizaId,
            json.EmpresaId,
        );

        return registro;
    }

    // * * * * * Gestiones de cobro * * * * *
    public plantillaGestionCobroDeJSON = (json: any): PlantillaGestionCobro => {
        if(!json) return null;
        var registro = new PlantillaGestionCobro(
            json.id,
            json.nombre,
            json.contenido,
            json.activo,
            json.isDeleted,
            json.EmpresaId,
        );

        return registro;
    }

    public gestionCobroDeJSON = (json: any): GestionCobro => {
        if(!json) return null;
        var registro = new GestionCobro(
            json.id,
            json.asunto,
            json.tipo,
            json.tipoAdjunto,
            json.contenido,
            json.filtros,
            json.correos,
            json.fecha,
            json.enviado,
            json.archivoEnviado,
            json.archivoEnviado2,
            json.idsFormulariosClientes,
            json.PlantillaGestionCobroId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(registro.idsFormulariosClientes) registro.idsFormulariosClientesArray = registro.idsFormulariosClientes.split(',').map(Number);

        if(registro.filtros) {
            registro.filtrosJSON = JSON.parse(registro.filtros);
        }

        if(registro.correos) {
            registro.correosArray = registro.correos.split(',');
        }

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(registro.tipo) {
            let tipo = this.apiURLs.tiposGestionesCobros.find(x => x.id == registro.tipo);
            registro.nombreTipo = tipo ? tipo.nombre : '';
        }

        if(json.PlantillaGestionCobro) {
            var plantilla = this.plantillaGestionCobroDeJSON(json.PlantillaGestionCobro);
            registro.plantillaGestionCobro = plantilla;
            registro.nombrePlantilla = plantilla.nombre;
        }

        return registro;
    }

    // * * * * * Mapas de proceso * * * * *
    public plantillaMapaProcesoDeJSON = (json: any): PlantillaMapaProceso => {
        if(!json) return null;
        var registro = new PlantillaMapaProceso(
            json.id,
            json.nombre,
            json.duracionDias,
            json.activo,
            json.isDeleted,
            json.EmpresaId,
        );

        if(json.RamoPlantillaMapaProcesos) {
            registro.ramos = [];
            registro.idsRamos = [];
            registro.nombreRamos = '';
            for (let i = 0; i < json.RamoPlantillaMapaProcesos.length; i++) {
                const element = json.RamoPlantillaMapaProcesos[i];
                if(element.Ramo) {
                    var ramo = this.ramoDeJSON(element.Ramo);
                    registro.ramos.push(ramo);
                    registro.idsRamos.push(element.RamoId);
                    if(registro.nombreRamos != '') registro.nombreRamos += ', ';
                    registro.nombreRamos += ramo.nombre;
                }
            }
        }

        if(json.PlantillaPasoMapaProcesos) {
            registro.pasos = [];
            for (let i = 0; i < json.PlantillaPasoMapaProcesos.length; i++) {
                const element = json.PlantillaPasoMapaProcesos[i];
                var paso = this.plantillaPasoMapaProcesoDeJSON(element);
                registro.pasos.push(paso);
            }
        }

        return registro;
    }

    public plantillaPasoMapaProcesoDeJSON = (json: any): PlantillaPasoMapaProceso => {
        if(!json) return null;
        var registro = new PlantillaPasoMapaProceso(
            json.id,
            json.numero,
            json.tipo,
            json.descripcion,
            json.duracionDias,
            json.activo,
            json.isDeleted,
            json.llevaPunteo,
            json.PlantillaMapaProcesoId,
            json.EmpresaId,
        );

        if(registro.tipo) {
            var indexUbicacion = this.indexOf(this.apiURLs.tiposPasosMapaProceso, 'id', registro.tipo);
            if(indexUbicacion != -1) registro.nombreTipo = this.apiURLs.tiposPasosMapaProceso[indexUbicacion].nombre;
        }

        if(json.PlantillaMapaProceso) {
            registro.plantillaMapa = this.plantillaMapaProcesoDeJSON(json.PlantillaMapaProceso);
        }

        return registro;
    }

    public mapaProcesoDeJSON = (json: any): MapaProceso => {
        if(!json) return null;
        var registro = new MapaProceso(
            json.id,
            json.observaciones,
            json.duracionDias,
            json.fechaCreacion,
            json.fechaFinalizacion,
            json.fechaActualizacion,
            json.numeroAseguradora,
            json.completado,
            json.cancelado,
            json.pasoActual,
            json.totalPasos,
            json.idExterno,
            json.PlantillaMapaProcesoId,
            json.UsuarioId,
            json.UsuarioResponsableId,
            json.EmpresaId,
        );
        registro.nombreMapa = 'Mapa #' + registro.id;
        if(registro.completado) registro.nombreEstado = 'Completado';
        else if(registro.cancelado) registro.nombreEstado = 'Cancelado';
        else registro.nombreEstado = 'En proceso';

        registro.nombrePasos = registro.pasoActual + '/' + registro.totalPasos;

        if(json.fechaCreacion) {
            registro.fechaCreacion = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCreacionFormato = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaCreacionDate = this.convertirFecha(json.fechaCreacion, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaFinalizacion) {
            registro.fechaFinalizacion = this.convertirFecha(json.fechaFinalizacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFinalizacionFormato = this.convertirFecha(json.fechaFinalizacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaFinalizacionDate = this.convertirFecha(json.fechaFinalizacion, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaActualizacion) {
            registro.fechaActualizacion = this.convertirFecha(json.fechaActualizacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaActualizacionFormato = this.convertirFecha(json.fechaActualizacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaActualizacionDate = this.convertirFecha(json.fechaActualizacion, 'YYYY-MM-DD', 'date');
        }

        if(json.PasoMapaProcesos) {
            registro.pasos = [];
            for (let i = 0; i < json.PasoMapaProcesos.length; i++) {
                const element = json.PasoMapaProcesos[i];
                var paso = this.pasoMapaProcesoDeJSON(element);
                registro.pasos.push(paso);
            }
        }

        if(json.CertificadoMapaProcesos) {
            registro.certificados = [];
            registro.idsCertificados = [];
            registro.nombreCertificados = '';
            for (let i = 0; i < json.CertificadoMapaProcesos.length; i++) {
                const element = json.CertificadoMapaProcesos[i];
                if(element.Certificado) {
                    var certificado = this.certificadoDeJSON(element.Certificado);
                    registro.certificados.push(certificado);
                    registro.idsCertificados.push(element.CertificadoId);
                    if(registro.nombreCertificados != '') registro.nombreCertificados += ', ';
                    registro.nombreCertificados += certificado.nombre;
                }
            }
        }

        if(json.PlantillaMapaProceso) {
            registro.plantilla = this.plantillaMapaProcesoDeJSON(json.PlantillaMapaProceso);
            registro.nombrePlantilla = registro.plantilla.nombre;
            registro.nombreMapa += ' - ' + registro.nombrePlantilla;
        }

        if(json.SolicitudPolizas && json.SolicitudPolizas.length > 0) {
            registro.solicitud = this.solicitudPolizaDeJSON(json.SolicitudPolizas[0]);
            registro.SolicitudPolizaId = registro.solicitud.id;
            registro.numeroSolicitudMigrada = registro.solicitud.solicitudIdAnterior;
        }

        if(json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
            registro.nombreUsuario = registro.usuario.nombreCompleto;
        }

        if(json.UsuarioResponsable) {
            registro.usuarioResponsable = this.usuarioDeJSON(json.UsuarioResponsable);
            registro.nombreUsuarioResponsable = registro.usuarioResponsable.nombreCompleto;
        }

        if(json.Poliza) {
            registro.poliza = this.polizaDeJSON(json.Poliza);
            registro.nombrePoliza = registro.poliza.nombrePoliza;
        }

        return registro;
    }

    public pasoMapaProcesoDeJSON = (json: any): PasoMapaProceso => {
        if(!json) return null;
        var registro = new PasoMapaProceso(
            json.id,
            json.numero,
            json.duplicado,
            json.numeroDuplicado,
            json.fechaAceptado,
            json.fechaCompletado,
            json.fechaAsignado,
            json.aceptado,
            json.completado,
            json.duracionDias,
            json.observaciones,
            json.puntuacion,
            json.MapaProcesoId,
            json.PlantillaPasoMapaProcesoId,
            json.UsuarioAsignadorId,
            json.UsuarioResponsableId,
            json.EmpresaId,
        );
        registro.numeroCompleto = registro.numero + '.';
        if(registro.duplicado && registro.numeroDuplicado) registro.numeroCompleto += registro.numeroDuplicado + '.';

        if(json.fechaAceptado) {
            registro.fechaAceptado = this.convertirFecha(json.fechaAceptado, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAceptadoFormato = this.convertirFechaATimezone(json.fechaAceptado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaAceptadoDate = this.convertirFecha(json.fechaAceptado, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaCompletado) {
            registro.fechaCompletado = this.convertirFecha(json.fechaCompletado, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCompletadoFormato = this.convertirFechaATimezone(json.fechaCompletado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaCompletadoDate = this.convertirFecha(json.fechaCompletado, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaAsignado) {
            registro.fechaAsignado = this.convertirFecha(json.fechaAsignado, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAsignadoFormato = this.convertirFechaATimezone(json.fechaAsignado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaAsignadoDate = this.convertirFecha(json.fechaAsignado, 'YYYY-MM-DD', 'date');
        }

        if(json.MapaProceso) {
            registro.mapaProceso = this.mapaProcesoDeJSON(json.MapaProceso);
        }

        if(json.PlantillaPasoMapaProceso) {
            registro.plantilla = this.plantillaPasoMapaProcesoDeJSON(json.PlantillaPasoMapaProceso);
            registro.nombrePaso = registro.plantilla.descripcion;
            registro.nombreTipo = registro.plantilla.nombreTipo;
            registro.tipoPaso = registro.plantilla.tipo;
        }

        if(json.UsuarioAsignador) {
            registro.usuarioAsignador = this.usuarioDeJSON(json.UsuarioAsignador);
            registro.usuariosAsignadoresAux = [registro.usuarioAsignador];
            registro.nombreUsuarioAsignador = registro.usuarioAsignador.nombreCompleto;
        }

        if(json.UsuarioResponsable) {
            registro.usuarioResponsable = this.usuarioDeJSON(json.UsuarioResponsable);
            registro.usuariosResponsablesAux = [registro.usuarioResponsable];
            registro.nombreUsuarioResponsable = registro.usuarioResponsable.nombreCompleto;
        }

        return registro;
    }

    public mensajeriaDeJSON = (json: any): Mensajeria => {
        if(!json) return null;
        var registro = new Mensajeria(
            json.id,
            json.tipo,
            json.tipoEnvio,
            json.estado,
            json.emisor,
            json.direccionEmisor,
            json.receptor,
            json.direccionReceptor,
            json.descripcion,
            json.fechaAreaEncargada,
            json.fechaRecepcion,
            json.fechaMensajero,
            json.fechaCliente,
            json.fechaProveedor,
            json.fechaCierre,
            json.fecha,
            json.cerrado,
            json.isDeleted,
            json.programarEnvio,
            json.fechaProgramarEnvio,
            json.UsuarioMensajeriaId,
            json.UsuarioEjecutivoId,
            json.UsuarioAsignadoId,
            json.PolizaId,
            json.ClienteId,
            json.AseguradoraId,
            json.SolicitudPolizaId,
            json.EmpresaId,
        );
        registro.nombreMensaje = 'Mensaje #' + registro.id;
        registro.meta = {};

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaProgramarEnvio) {
            registro.fechaProgramarEnvio = this.convertirFecha(json.fechaProgramarEnvio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaProgramarEnvioFormato = this.convertirFecha(json.fechaProgramarEnvio, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaProgramarEnvioDate = this.convertirFecha(json.fechaProgramarEnvio, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaAreaEncargada) {
            registro.fechaAreaEncargada = this.convertirFecha(json.fechaAreaEncargada, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAreaEncargadaFormato = this.convertirFechaATimezone(json.fechaAreaEncargada, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaAreaEncargadaDate = this.convertirFecha(json.fechaAreaEncargada, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaRecepcion) {
            registro.fechaRecepcion = this.convertirFecha(json.fechaRecepcion, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaRecepcionFormato = this.convertirFechaATimezone(json.fechaRecepcion, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaRecepcionDate = this.convertirFecha(json.fechaRecepcion, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaMensajero) {
            registro.fechaMensajero = this.convertirFecha(json.fechaMensajero, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaMensajeroFormato = this.convertirFechaATimezone(json.fechaMensajero, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaMensajeroDate = this.convertirFecha(json.fechaMensajero, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaCliente) {
            registro.fechaCliente = this.convertirFecha(json.fechaCliente, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaClienteFormato = this.convertirFechaATimezone(json.fechaCliente, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaClienteDate = this.convertirFecha(json.fechaCliente, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaProveedor) {
            registro.fechaProveedor = this.convertirFecha(json.fechaProveedor, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaProveedorFormato = this.convertirFechaATimezone(json.fechaProveedor, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaProveedorDate = this.convertirFecha(json.fechaProveedor, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaCierre) {
            registro.fechaCierre = this.convertirFecha(json.fechaCierre, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaCierreFormato = this.convertirFechaATimezone(json.fechaCierre, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaCierreDate = this.convertirFecha(json.fechaCierre, 'YYYY-MM-DD', 'date');
        }

        if(json.UsuarioMensajeria) {
            registro.usuarioMensajeria = this.usuarioDeJSON(json.UsuarioMensajeria);
            registro.nombreUsuarioMensajeria = registro.usuarioMensajeria.nombreCompleto;
        }

        if(json.UsuarioEjecutivo) {
            registro.usuarioEjecutivo = this.usuarioDeJSON(json.UsuarioEjecutivo);
            registro.nombreUsuarioEjecutivo = registro.usuarioEjecutivo.nombreCompleto;
        }

        if(json.UsuarioAsignado) {
            registro.usuarioAsignado = this.usuarioDeJSON(json.UsuarioAsignado);
            registro.nombreUsuarioAsignado = registro.usuarioAsignado.nombreCompleto;
        }

        if(json.Poliza) {
            registro.poliza = this.polizaDeJSON(json.Poliza);
        }

        if(json.CertificadoMensajeria) {
            registro.certificados = [];
            registro.idsCertificados = [];
            registro.nombreCertificados = '';
            for (let i = 0; i < json.CertificadoMensajeria.length; i++) {
                const element = json.CertificadoMensajeria[i];
                if(element.Certificado) {
                    var certificado = this.certificadoDeJSON(element.Certificado);
                    registro.certificados.push(certificado);
                    registro.idsCertificados.push(element.CertificadoId);
                    if(registro.nombreCertificados != '') registro.nombreCertificados += ', ';
                    registro.nombreCertificados += certificado.nombre;
                }
            }
        }

        if(json.DocumentoCobros) {
            registro.cobros = [];
            registro.idsCobros = [];
            for (let element of json.DocumentoCobros) {
                var cobro = this.documentoCobroDeJSON(element);
                registro.cobros.push(cobro);
                registro.idsCobros.push(cobro.id);
            }
        }

        if(registro.tipo) {
            registro.nombreTipo = this.apiURLs.tiposMensajeria[this.indexOf(this.apiURLs.tiposMensajeria, 'id', registro.tipo)].nombre;
            if(registro.tipo == 'envio') registro.meta.origenDestino = registro.receptor;
            if(registro.tipo == 'recepcion') registro.meta.origenDestino = registro.emisor;
        }

        if(registro.estado) {
            registro.nombreEstado = this.apiURLs.estadosMensajeria[this.indexOf(this.apiURLs.estadosMensajeria, 'id', registro.estado)].nombre;
            if(registro.cerrado) registro.nombreEstado = 'Cerrado';
        }

        return registro;
    }

    public reclamoDeJSON = (json: any): Reclamo => {
        try {
            if(!json) return null;
            var registro = new Reclamo(
                json.id,
                json.tipoReclamo,
                json.numeroInterno,
                json.numeroAseguradora,
                json.estado,
                json.fechaIngreso,
                json.fechaReportado,
                json.fechaPresentadoAseguradora,
                json.fechaOcurrido,
                json.fechaPagado,
                json.fechaCierre,
                json.fechaRecordatorio,
                json.observaciones,
                json.observacionesSiniestro,
                json.observacionesFacturas,
                json.direccionSiniestro,
                json.descripcionSiniestro,
                json.emergencia,
                json.activo,
                json.isDeleted,
                json.tipoCambio,
                json.totalFacturado,
                json.montoReclamado,
                json.montoPagado,
                json.ajustador,
                json.taller,
                json.numeroComplemento,
                json.diagnostico,
                json.personaAfectada,
                json.aplicaEnvioAvisoAperturaCierre,
                json.avisoReclamoMaternidad,
                json.fechaAltaComplemento,
                json.ultimaVisualizacion,
                json.monedaInternacional,
                json.proveedor,
                json.nombrePaciente,
                json.telefono1,
                json.telefono2,
                json.reporteAseguradora,
                json.horaRecepcionAutorizacion,
                json.horaAutorizacionAsegurado,
                json.perdidaTotalRobo,
                json.roboEquipoEspecial,
                json.reinstalacionSumaAsegurada,
                json.datosTerceros,
                json.tipoAutorizacion,
                json.correo1,
                json.UsuarioId,
                json.UsuarioEncargadoId,
                json.PolizaId,
                json.TipoSiniestroId,
                json.TipoReclamoId,
                json.MonedaId,
                json.EmpresaId,
            );

            registro.numeroInternoMostrar = registro.numeroInterno;
            if(!registro.numeroInterno) registro.numeroInternoMostrar = 'PRO-' + registro.id;

            let nombreReclamo = 'Reclamo';
            if(registro.numeroAseguradora) nombreReclamo += ' no. ' + registro.numeroAseguradora;
            else nombreReclamo += ' pendiente aseguradora (interno #' + registro.id + ')';
            registro.nombreReclamo = nombreReclamo;

            if(json.ultimaVisualizacion) {
                registro.ultimaVisualizacion = this.convertirFecha(json.ultimaVisualizacion, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.ultimaVisualizacionFormato = this.convertirFecha(json.ultimaVisualizacion, 'YYYY-MM-DD', 'DD/MM/YYYY');
            }

            if(json.fechaIngreso) {
                registro.fechaIngreso = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaIngresoFormato = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaIngresoDate = this.convertirFecha(json.fechaIngreso, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaAltaComplemento) {
                registro.fechaAltaComplemento = this.convertirFecha(json.fechaAltaComplemento, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaAltaComplementoFormato = this.convertirFecha(json.fechaAltaComplemento, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaAltaComplementoDate = this.convertirFecha(json.fechaAltaComplemento, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaReportado) {
                registro.fechaReportado = this.convertirFecha(json.fechaReportado, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm');
                registro.fechaReportadoFormato = this.convertirFechaATimezone(json.fechaReportado, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
                registro.fechaReportadoDate = this.convertirFecha(json.fechaReportado, 'YYYY-MM-DD HH:mm', 'date');
            }

            if(json.fechaPresentadoAseguradora) {
                registro.fechaPresentadoAseguradora = this.convertirFecha(json.fechaPresentadoAseguradora, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm');
                registro.fechaPresentadoAseguradoraFormato = this.convertirFechaATimezone(json.fechaPresentadoAseguradora, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
                registro.fechaPresentadoAseguradoraDate = this.convertirFecha(json.fechaPresentadoAseguradora, 'YYYY-MM-DD HH:mm', 'date');
            }

            if(json.fechaOcurrido) {
                registro.fechaOcurrido = this.convertirFecha(json.fechaOcurrido, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaOcurridoFormato = this.convertirFecha(json.fechaOcurrido, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaOcurridoDate = this.convertirFecha(json.fechaOcurrido, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaPagado) {
                registro.fechaPagado = this.convertirFecha(json.fechaPagado, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaPagadoFormato = this.convertirFecha(json.fechaPagado, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaPagadoDate = this.convertirFecha(json.fechaPagado, 'YYYY-MM-DD', 'date');
                if(registro.fechaIngresoDate) {
                    registro.diasProceso = moment(registro.fechaPagadoDate).diff(registro.fechaIngresoDate, 'days');
                }
            }
            else {
                if(registro.fechaIngresoDate) {
                    registro.diasProceso = moment(new Date()).diff(registro.fechaIngresoDate, 'days');
                }
            }

            if(json.fechaCierre) {
                registro.fechaCierre = this.convertirFecha(json.fechaCierre, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaCierreFormato = this.convertirFecha(json.fechaCierre, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaCierreDate = this.convertirFecha(json.fechaCierre, 'YYYY-MM-DD', 'date');
            }

            if(json.fechaRecordatorio) {
                registro.fechaRecordatorio = this.convertirFecha(json.fechaRecordatorio, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaRecordatorioFormato = this.convertirFecha(json.fechaRecordatorio, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaRecordatorioDate = this.convertirFecha(json.fechaRecordatorio, 'YYYY-MM-DD', 'date');
            }

            if(registro.estado) {
                var index = this.indexOf(this.apiURLs.estadosReclamos, 'id', registro.estado);
                if(index != -1) registro.nombreEstado = this.apiURLs.estadosReclamos[index].nombre;
            }

            if(registro.tipoReclamo) {
                var index = this.indexOf(this.apiURLs.tiposReclamos, 'id', registro.tipoReclamo);
                if(index != -1) registro.nombreTipoReclamo = this.apiURLs.tiposReclamos[index].nombre;
            }

            if(json.Moneda) {
                var moneda = this.monedaDeJSON(json.Moneda);
                registro.moneda = moneda;
            }

            if(json.TipoReclamo) {
                var modelTipoReclamo = this.tipoReclamoDeJSON(json.TipoReclamo);
                registro.modelTipoReclamo = modelTipoReclamo;
            }

            if(json.UltimoSeguimiento) {
                let ultimoSeguimiento = this.seguimientoReclamoDeJSON(json.UltimoSeguimiento);
                registro.ultimoSeguimiento = ultimoSeguimiento;
                if(ultimoSeguimiento.fechaSeguimiento) {
                    registro.fechaUltimoSeguimientoFormato = ultimoSeguimiento.fechaSeguimientoFormato;
                }
            }

            if(json.ValorReclamos) {
                registro.valores = [];
                for (let i = 0; i < json.ValorReclamos.length; i++) {
                    const element = json.ValorReclamos[i];
                    var valor = this.valorReclamoDeJSON(element);
                    registro.valores.push(valor);
                }
            }

            if(json.Poliza) {
                var poliza = this.polizaDeJSON(json.Poliza);
                registro.poliza = poliza;
                registro.nombrePoliza = poliza.nombrePoliza;
                registro.nombreRamo = poliza.nombreRamo;
                registro.nombreCliente = poliza.nombreCliente;
                registro.nombreAseguradora = poliza.nombreAseguradora;
            }

            if(json.TipoSiniestro) {
                var tipoSiniestro = this.tipoSiniestroDeJSON(json.TipoSiniestro);
                registro.tipoSiniestro = tipoSiniestro;
                registro.nombreTipoSiniestro = tipoSiniestro.nombre;
            }

            if(json.Usuario) {
                var usuario = this.usuarioDeJSON(json.Usuario);
                registro.usuario = usuario;
                registro.nombreUsuario = usuario.nombreCompleto;
            }

            if(json.UsuarioEncargado) {
                var usuarioEncargado = this.usuarioDeJSON(json.UsuarioEncargado);
                registro.usuarioEncargado = usuarioEncargado;
                registro.nombreUsuarioEncargado = usuarioEncargado.nombreCompleto;
            }

            if(json.SeguimientoReclamos) {
                registro.seguimientos = [];
                for (let i = 0; i < json.SeguimientoReclamos.length; i++) {
                    const element = json.SeguimientoReclamos[i];
                    var seguimiento = this.seguimientoReclamoDeJSON(element);
                    registro.seguimientos.push(seguimiento);
                }
            }

            if(json.FacturaReclamos) {
                registro.facturas = [];
                for (let i = 0; i < json.FacturaReclamos.length; i++) {
                    const element = json.FacturaReclamos[i];
                    var factura = this.facturaReclamoDeJSON(element);
                    registro.facturas.push(factura);
                }
            }

            if(json.RazonRamoReclamos) {
                registro.idsRazones = [];
                registro.razones = [];
                for (let i = 0; i < json.RazonRamoReclamos.length; i++) {
                    const element = json.RazonRamoReclamos[i];
                    registro.idsRazones.push(element.RazonRamoId);
                    if(element.RazonRamo) {
                        var razon = this.razonRamoDeJSON(element.RazonRamo);
                        registro.razones.push(razon);
                    }
                }
            }

            if(json.CertificadoReclamos) {
                registro.certificados = [];
                for (let i = 0; i < json.CertificadoReclamos.length; i++) {
                    const element = json.CertificadoReclamos[i];
                    if(element) {
                        var certificadoReclamo = this.certificadoReclamoDeJSON(element);
                        var totalesPagosDependientes = json.totalesPagosDependientes;
                        if(totalesPagosDependientes) {
                            var index = this.indexOf(totalesPagosDependientes, 'BeneficiarioDependienteCertificadoId', certificadoReclamo.BeneficiarioDependienteCertificadoId);
                            if(index != -1) certificadoReclamo.montoAcumulado = totalesPagosDependientes[index].monto;
                        }
                        if(certificadoReclamo.certificado) registro.numeroCertificado = certificadoReclamo.certificado.numero;
                        registro.certificados.push(certificadoReclamo);
                    }
                }
            }

            if(registro.personaAfectada) {
                var index = this.indexOf(this.apiURLs.personasAfectadasReclamos, 'id', registro.personaAfectada);
                if(index != -1) registro.nombrePersonaAfectada = this.apiURLs.personasAfectadasReclamos[index].nombre;

                if(registro.personaAfectada == 'titular' && registro.certificados && registro.certificados.length > 0 && registro.certificados[0].certificado.nombre) {
                    registro.nombreAfectado = registro.certificados[0].certificado.nombreCertificadoResumen;
                }
                else if(registro.personaAfectada == 'dependiente' && registro.certificados && registro.certificados.length > 0 && registro.certificados[0].beneficiarioDependiente) {
                    registro.nombreAfectado = registro.certificados[0].beneficiarioDependiente.nombre;
                }
                else registro.nombreAfectado = registro.nombrePersonaAfectada;
            }

            // Nombre de breadcrumbs
            registro.nombreBreadcrumbs = '';
            if(registro.numeroAseguradora) registro.nombreBreadcrumbs += 'Reclamo no. ' + registro.numeroAseguradora;
            registro.nombreBreadcrumbs += ' - Interno no. ' + registro.id;
            if(registro.poliza) registro.nombreBreadcrumbs += ' - Póliza no. ' + registro.poliza.numero;
            if(registro.numeroCertificado) registro.nombreBreadcrumbs += ' - Certificado no. ' + registro.numeroCertificado;
            if(registro.poliza && registro.poliza.cliente) registro.nombreBreadcrumbs += ' (' + registro.poliza.cliente.nombreCompleto + ')';
            if(registro.nombreAseguradora) registro.nombreBreadcrumbs += ' - ' + registro.nombreAseguradora;

            return registro;
        } catch(error) {
            console.error(error);
            return null;
        }
    }

    public certificadoReclamoDeJSON = (json: any): CertificadoReclamo => {
        if(!json) return null;
        var registro = new CertificadoReclamo(
            json.id,
            json.montoDeducible,
            json.observaciones,
            json.montoReclamado,
            json.montoReserva,
            json.impuesto,
            json.timbres,
            json.coaseguros,
            json.gastosNoElegibles,
            json.gastosCubiertos,
            json.montoNoPagado,
            json.montoPagado,
            json.depreciacion,
            json.tipoPago,
            json.numeroCheque,
            json.reclamoReportadoDirecto,
            json.pagoDirectoTaller,
            json.reclamoTitular,
            json.ReclamoId,
            json.CertificadoId,
            json.BeneficiarioDependienteCertificadoId,
            json.IncisoCertificadoId,
            json.DeducibleCertificadoId,
            json.DeducibleMonedaCertificadoId,
            json.EmpresaId,
        );

        if(registro.coaseguros) {
            registro.aplicarCoaseguros = true;
        }

        if(json.datosDeducibles) {
            registro.deducibleRestante = json.datosDeducibles.deducibleRestante;
            registro.totalDeducible = json.datosDeducibles.totalDeducible;
            registro.totalPagado = json.datosDeducibles.totalPagado;
        }

        if(json.CoberturaCertificadoReclamos) {
            registro.coberturas = [];
            registro.idsCoberturasIncisos = [];
            for (let i = 0; i < json.CoberturaCertificadoReclamos.length; i++) {
                const element = json.CoberturaCertificadoReclamos[i];
                var cobertura = this.coberturaCertificadoReclamoDeJSON(element);
                registro.coberturas.push(cobertura);
                registro.idsCoberturasIncisos.push(cobertura.CoberturaIncisoCertificadoId);
            }
        }

        if(json.BeneficiarioDependienteCertificado) {
            var beneficiario = this.beneficiarioDependienteCertificadoDeJSON(json.BeneficiarioDependienteCertificado);
            registro.beneficiarioDependiente = beneficiario;
        }

        if(json.Certificado) {
            var certificado = this.certificadoDeJSON(json.Certificado);
            registro.certificado = certificado;
            registro.nombreCertificado = certificado.nombreCertificado;
        }

        return registro;
    }

    public coberturaCertificadoReclamoDeJSON = (json: any): CoberturaCertificadoReclamo => {
        if(!json) return null;
        var registro = new CoberturaCertificadoReclamo(
            json.id,
            json.montoDeducible,
            json.CoberturaIncisoCertificadoId,
            json.CertificadoReclamoId,
            json.DeducibleCertificadoId,
        );

        if(json.DeducibleCertificado) {
            var deducibleCertificado = this.deducibleCertificadoDeJSON(json.DeducibleCertificado);
            registro.deducibleCertificado = deducibleCertificado;
        }

        if(json.CoberturaIncisoCertificado) {
            var coberturaIncisoCertificado = this.coberturaIncisoCertificadoDeJSON(json.CoberturaIncisoCertificado);
            registro.coberturaIncisoCertificado = coberturaIncisoCertificado;
        }

        return registro;
    }

    public seguimientoReclamoDeJSON = (json: any): SeguimientoReclamo => {
        if(!json) return null;
        var registro = new SeguimientoReclamo(
            json.id,
            json.numero,
            json.observaciones,
            json.fecha,
            json.fechaSeguimiento,
            json.horaRecordatorio,
            json.realizado,
            json.ReclamoId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(registro.observaciones) {
            registro.observacionesResumen = registro.observaciones.substr(0, 100);
            if(registro.observaciones.length > 100) registro.observacionesResumen += '...';
        }

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaSeguimiento) {
            registro.fechaSeguimiento = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaSeguimientoFormato = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaSeguimientoDate = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'date');
            if(registro.horaRecordatorio) registro.fechaSeguimientoFormato += ' ' + registro.horaRecordatorio;
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuario = usuario.nombreCompleto;
        }

        if(json.Reclamo) {
            var reclamo = this.reclamoDeJSON(json.Reclamo);
            registro.reclamo = reclamo;
            registro.numeroReclamo = reclamo.nombreReclamo;
        }

        return registro;
    }

    public aclaracionPolizaDeJSON = (json: any): AclaracionPoliza => {
        if(!json) return null;
        var registro = new AclaracionPoliza(
            json.id,
            json.observaciones,
            json.nombreUsuario,
            json.fecha,
            json.PolizaId,
            json.UsuarioId,
            json.EmpresaId,
        );

        if(registro.observaciones) {
            registro.observacionesResumen = registro.observaciones.substr(0, 100);
            if(registro.observaciones.length > 100) registro.observacionesResumen += '...';
        }

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuarioIngreso = usuario.nombreCompleto;
        }

        return registro;
    }

    public clausulaGarantiaPolizaDeJSON = (json: any): ClausulaGarantiaPoliza => {
        if(!json) return null;
        var registro = new ClausulaGarantiaPoliza(
            json.id,
            json.nombreUsuario,
            json.beneficiario,
            json.descripcion,
            json.propietario,
            json.valorClausula,
            json.fecha,
            json.fechaAlta,
            json.fechaBaja,
            json.estado,
            json.PolizaId,
            json.MonedaId,
            json.UsuarioId,
            json.EmpresaId,
            json.CertificadoId,
        );

        if(registro.descripcion) {
            registro.descripcionResumen = registro.descripcion.substr(0, 100);
            if(registro.descripcion.length > 100) registro.descripcionResumen += '...';
        }

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaAlta) {
            registro.fechaAlta = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaAltaFormato = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaAltaDate = this.convertirFecha(json.fechaAlta, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaBaja) {
            registro.fechaBaja = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaBajaFormato = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaBajaDate = this.convertirFecha(json.fechaBaja, 'YYYY-MM-DD', 'date');
        }

        if(json.Usuario) {
            var usuario = this.usuarioDeJSON(json.Usuario);
            registro.usuario = usuario;
            registro.nombreUsuarioIngreso = usuario.nombreCompleto;
        }

        return registro;
    }

    public facturaReclamoDeJSON = (json: any): FacturaReclamo => {
        try {
            if(!json) return null;
            var registro = new FacturaReclamo(
                json.id,
                json.fecha,
                json.numero,
                json.estado,
                json.proveedor,
                json.descripcion,
                json.monto,
                json.valorCubierto,
                json.valorNoCubierto,
                json.impuestoCubierto,
                json.impuestoNoCubierto,
                json.entregada,
                json.noAplicarImpuesto,
                json.mostrarDatosMonedaOriginal,
                json.tipoCambio,
                json.montoOriginal,
                json.motivoRechazo,
                json.isDeleted,
                json.ReclamoId,
                json.CertificadoReclamoId,
                json.RazonRamoId,
                json.MonedaId,
                json.MonedaAnteriorId,
                json.EmpresaId,
            );

            registro.nombreFactura = 'Factura de reclamo no. ' + registro.numero;

            if(registro.estado) {
                var index = this.indexOf(this.apiURLs.estadosFacturasReclamos, 'id', registro.estado);
                if(index != -1) registro.nombreEstado = this.apiURLs.estadosFacturasReclamos[index].nombre;
            }

            if(json.fecha) {
                registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
                registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
                registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
            }

            if(json.Moneda) {
                var moneda = this.monedaDeJSON(json.Moneda);
                registro.moneda = moneda;
            }

            if(json.Reclamo) {
                var reclamo = this.reclamoDeJSON(json.Reclamo);
                registro.reclamo = reclamo;
            }

            if(json.CertificadoReclamo) {
                var certificadoReclamo = this.certificadoReclamoDeJSON(json.CertificadoReclamo);
                registro.certificadoReclamo = certificadoReclamo;
                registro.nombreCertificado = certificadoReclamo.nombreCertificado;
            }

            if(json.RazonRamo) {
                var razonRamo = this.razonRamoDeJSON(json.RazonRamo);
                registro.razonRamo = razonRamo;
                registro.nombreRazonRamo = razonRamo.nombre;
            }

            return registro;
        } catch(error) {
            console.error(error);
            return null;
        }
    }

    public plantillaSolicitudDeJSON = (json: any): PlantillaSolicitud => {
        if(!json) return null;
        var registro = new PlantillaSolicitud(
            json.id,
            json.nombre,
            json.tipo,
            json.tipoUso,
            json.descripcion,
            json.titulo,
            json.encabezados,
            json.secciones,
            json.aseguradora,
            json.usaCertificados,
            json.soloIncluidos,
            json.soloExcluidos,
            json.ignorarExcluidos,
            json.seleccionarDeclaraciones,
            json.activo,
            json.eliminado,
            json.idsPlantillasMapas,
            json.idsRamos,
            json.aplicaPolizas,
            json.aplicaReclamos,
            json.aplicaCobros,
            json.aplicaClientes,
            json.plantillaEspecial,
            json.usarEnEnvioAseguradoraReclamos,
            json.usarEnEnvioClienteReclamos,
            json.esconderMontosTotales,
            json.esconderAclaracionesPoliza,
            json.ignorarNotasAdicionalesSolicitud,
            json.usarNumeroEmision,
            json.esconderPrimasSumas,
            json.soloReclamosPagados,
            json.TipoReclamoId,
            json.EmpresaId,
        );

        if(registro.tipo) {
            let index = this.indexOf(this.apiURLs.tiposPlantillasSolicitudes, 'id', registro.tipo);
            if(index != -1) registro.nombreTipo = this.apiURLs.tiposPlantillasSolicitudes[index].nombre;
        }

        if(registro.tipoUso) {
            let index = this.indexOf(this.apiURLs.tiposUsosPlantillasSolicitudes, 'id', registro.tipoUso);
            if(index != -1) registro.nombreTipoUso = this.apiURLs.tiposUsosPlantillasSolicitudes[index].nombre;
        }

        if(registro.idsPlantillasMapas) {
            registro.idsPlantillasMapasArray = registro.idsPlantillasMapas.split(',');
            for (let i = 0; i < registro.idsPlantillasMapasArray.length; i++) registro.idsPlantillasMapasArray[i] = parseInt(registro.idsPlantillasMapasArray[i]);
        }

        if(registro.idsRamos) {
            registro.idsRamosArray = registro.idsRamos.split(',');
            for (let i = 0; i < registro.idsRamosArray.length; i++) registro.idsRamosArray[i] = parseInt(registro.idsRamosArray[i]);
        }

        if (json.secciones) {
            registro.modelSecciones = JSON.parse(json.secciones);
        }

        return registro;
    }

    public solicitudPolizaDeJSON = (json: any): SolicitudPoliza => {
        if (!json) return null;
        var registro = new SolicitudPoliza(
            json.id,
            json.fecha,
            json.encabezados,
            json.contenido,
            json.de,
            json.para,
            json.asunto,
            json.notasAdicionales,
            json.nombreArchivo,
            json.nombreTipo,
            json.puntuacion,
            json.estado,
            json.enviada,
            json.fechaEnvio,
            json.esconderMontosTotales,
            json.ignorarDatosCertificados,
            json.filtrarAclaraciones,
            json.idsAclaraciones,
            json.UsuarioId,
            json.PolizaId,
            json.ReclamoId,
            json.DocumentoCobroId,
            json.ClienteId,
            json.PlantillaSolicitudId,
            json.EmpresaId,
            json.MapaProcesoId,
        );
        registro.idExterno = json.idExterno;

        if(json.solicitudServicioId) {
            registro.solicitudServicioId = json.solicitudServicioId;
        }

        if(json.idsAclaraciones) {
            registro.idsAclaracionesArray = json.idsAclaraciones.split(',').map(Number);
        }

        if(json.solicitudIdAnterior) {
            registro.solicitudIdAnterior = json.solicitudIdAnterior;
        }

        if(registro.solicitudIdAnterior) registro.numero = registro.solicitudIdAnterior;
        else registro.numero = registro.id;

        if (json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.fechaEnvio) {
            registro.fechaEnvio = this.convertirFecha(json.fechaEnvio, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaEnvioFormato = this.convertirFechaATimezone(json.fechaEnvio, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
        }

        if (json.Usuario) {
            registro.usuario = this.usuarioDeJSON(json.Usuario);
        }

        if (json.PlantillaSolicitud) {
            registro.plantilla = this.plantillaSolicitudDeJSON(json.PlantillaSolicitud);
            registro.nombrePlantilla = registro.plantilla.nombre;
        }

        if (json.Poliza) {
            registro.poliza = this.polizaDeJSON(json.Poliza);
            if(registro.poliza.cliente) {
                registro.cliente = registro.poliza.cliente;
            }
        }

        if (json.Cliente) {
            registro.cliente = this.usuarioDeJSON(json.Cliente);
        }

        if (json.Reclamo) {
            registro.reclamo = this.reclamoDeJSON(json.Reclamo);
        }

        if (json.Empresa) {
            registro.empresa = this.empresaDeJSON(json.Empresa);
        }

        if(json.MapaProceso) {
            registro.mapa = this.mapaProcesoDeJSON(json.MapaProceso);
            registro.PlantillaMapaProcesoId = registro.mapa.PlantillaMapaProcesoId;
        }

        if(json.estado) {
            let estado = this.apiURLs.estadosSolicitudesPolizas.find(el => json.estado == el.id);
            if(estado) registro.nombreEstado = estado.nombre;
        }

        registro.relacionesCertificado = [];
        registro.certificados = [];
        registro.idsCertificados = [];
        if (json.CertificadoSolicituds) {
            for (let relacion of json.CertificadoSolicituds) {
                var relacionCertificado = this.certificadoSolicitudDeJSON(relacion);
                registro.relacionesCertificado.push(relacionCertificado);
                registro.idsCertificados.push(relacionCertificado.CertificadoId);
                if(relacionCertificado.certificado) {
                    registro.certificados.push(relacionCertificado.certificado);
                }
            }
        }

        if(registro.solicitudServicioId) {
            registro.urlVieja = `https://logica.prointelseguros.com/SiSegX/UploadSiseg10/Empresas/1/Servicios/${registro.solicitudServicioId}/1/${registro.nombreArchivo}`;
        }
        else if(registro.nombreArchivo) {
            registro.urlVieja = registro.nombreArchivo;
        }

        return registro;
    }

    public certificadoSolicitudDeJSON = (json: any): CertificadoSolicitud => {
        if (!json) return null;
        var registro = new CertificadoSolicitud(
            json.id,
            json.observaciones,
            json.idsDeclaraciones,
            json.SolicitudPolizaId,
            json.CertificadoId,
            json.EmpresaId,
        );

        if(registro.idsDeclaraciones) registro.idsDeclaracionesArray = registro.idsDeclaraciones.split(',');

        if (json.SolicitudPoliza) {
            registro.solicitud = this.solicitudPolizaDeJSON(json.SolicitudPoliza);
        }

        if (json.Certificado) {
            registro.certificado = this.certificadoDeJSON(json.Certificado);
        }

        return registro;
    }

    public plantillaRamoDeJSON = (json: any): PlantillaRamo => {
        if(!json) return null;
        var registro = new PlantillaRamo(
            json.id,
            json.nombre,
            json.descripcion,
            json.notasAdicionales,
            json.eliminado,
            json.activo,
            json.RamoId,
            json.EmpresaId,
        );

        if(json.Ramo) {
            var ramo = this.ramoDeJSON(json.Ramo);
            registro.ramo = ramo;
            registro.nombreRamo = ramo.nombre;
        }

        if(json.IncisoPlantillaRamos) {
            registro.incisos = [];
            for (let i = 0; i < json.IncisoPlantillaRamos.length; i++) {
                const element = json.IncisoPlantillaRamos[i];
                var inciso = this.incisoPlantilloRamoDeJSON(element);
                registro.incisos.push(inciso);
            }
        }

        return registro;
    }

    public incisoPlantilloRamoDeJSON = (json: any): IncisoPlantillaRamo => {
        if(!json) return null;
        var registro = new IncisoPlantillaRamo(
            json.id,
            json.nombre,
            json.declarativo,
            json.acumulaSuma,
            json.descripcion,
            json.sumaAsegurada,
            json.primaFacturada,
            json.primaSinDependientes,
            json.primaCon1Dependiente,
            json.primaConMasDependientes,
            json.primaAnualizada,
            json.primaEnDeposito,
            json.tipoCambio,
            json.porcentajeTasa,
            json.tarifa,
            json.tipoTasa,
            json.PlantillaRamoId,
            json.EmpresaId,
        );

        if(json.PlantillaRamo) {
            var plantillaRamo = this.plantillaRamoDeJSON(json.PlantillaRamo);
            registro.plantillaRamo = plantillaRamo;
        }

        if(json.CoberturaIncisoPlantillaRamos) {
            registro.coberturas = [];
            registro.idsCoberturas = [];
            for (let i = 0; i < json.CoberturaIncisoPlantillaRamos.length; i++) {
                const element = json.CoberturaIncisoPlantillaRamos[i];
                if(element.Cobertura) {
                    var cobertura = this.coberturaDeJSON(element.Cobertura);
                    cobertura.sumaAseguradaCertificado = element.sumaAsegurada;
                    registro.coberturas.push(cobertura);
                    registro.idsCoberturas.push(cobertura.id);
                }
            }
        }

        if(json.DeducibleIncisoPlantillaRamos) {
            registro.deducibles = [];
            registro.idsDeducibles = [];
            for (let i = 0; i < json.DeducibleIncisoPlantillaRamos.length; i++) {
                const element = json.DeducibleIncisoPlantillaRamos[i];
                var deducible = this.deducibleIncisoPlantillaRamoDeJSON(element);
                registro.deducibles.push(deducible);
                registro.idsDeducibles.push(deducible.id);
            }
        }

        return registro;
    }

    public deducibleIncisoPlantillaRamoDeJSON = (json: any): DeducibleIncisoPlantillaRamo => {
        if(!json) return null;
        var registro = new DeducibleIncisoPlantillaRamo(
            json.id,
            json.nombre,
            json.porcentaje,
            json.tipoBase,
            json.dias,
            json.valorMaximo,
            json.valorMinimo,
            json.valorFijo,
            json.tipoCambio,
            json.observaciones,
            json.IncisoPlantillaRamoId,
            json.EmpresaId,
        );

        registro.nombreDeducible = registro.nombre;
        if(registro.porcentaje) registro.nombreDeducible += ' (' + registro.porcentaje + '%)';

        return registro;
    }

    public coberturaIncisoPlantillaRamoDeJSON = (json: any): CoberturaIncisoPlantillaRamo => {
        if(!json) return null;

        var registro = new CoberturaIncisoPlantillaRamo(
            json.id,
            json.IncisoPlantillaRamoId,
            json.CoberturaId,
        );

        if(json.Cobertura) {
            var cobertura = this.coberturaDeJSON(json.Cobertura);
            registro.cobertura = cobertura;
            registro.nombreCobertura = cobertura.nombre;
        }

        return registro;
    }

    public seguimientoProspectoDeJSON = (json: any): SeguimientoProspecto => {
      if(!json) return null;
      var registro = new SeguimientoProspecto(
          json.id,
          json.numero,
          json.observaciones,
          json.fecha,
          json.fechaSeguimiento,
          json.ProspectoId,
          json.UsuarioId,
          json.EmpresaId,
      );

      if(registro.observaciones) {
          registro.observacionesResumen = registro.observaciones.substr(0, 100);
          if(registro.observaciones.length > 100) registro.observacionesResumen += '...';
      }

      if(json.fecha) {
          registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
          registro.fechaFormato = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
          registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
      }

      if(json.fechaSeguimiento) {
          registro.fechaSeguimiento = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'YYYY-MM-DD');
          registro.fechaSeguimientoFormato = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'DD/MM/YYYY');
          registro.fechaSeguimientoDate = this.convertirFecha(json.fechaSeguimiento, 'YYYY-MM-DD', 'date');
      }

      if(json.Usuario) {
          var usuario = this.usuarioDeJSON(json.Usuario);
          registro.usuario = usuario;
          registro.nombreUsuario = usuario.nombreCompleto;
      }

      return registro;
    }

    public miAnalyticDeJSON = (json: any): MiAnalytic => {
      if(!json) return null;
      var registro = new MiAnalytic(
          json.id,
          json.tipoGrafica,
          json.parametros,
          json.activo,
          json.EmpresaId,
          json.UsuarioId,
      );

      if(json.Empresa) {
          var Empresa = this.empresaDeJSON(json.Empresa);
          registro.Empresa = Empresa;
      }

      if(json.Usuario) {
          var Usuario = this.usuarioDeJSON(json.Usuario);
          registro.Usuario = Usuario;
      }

      return registro;
  }

  public bitacoraClausulaGarantiaDeJSON = (json: any): BitacoraClausulaGarantia => {
    if(!json) return null;
    var registro = new BitacoraClausulaGarantia(
        json.id,
        json.estadoAnterior,
        json.estadoNuevo,
        json.fecha,
        json.ClausulaGarantiaPolizaId,
        json.UsuarioId,
    );

    if(json.fecha) {
        registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
        registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
        registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
    }

    if(json.Usuario) {
        var usuario = this.usuarioDeJSON(json.Usuario);
        registro.usuario = usuario;
    }

    return registro;
  }

    public bitacoraCambioRiesgoClienteDeJSON = (json: any): BitacoraCambioRiesgoCliente => {
        if(!json) return null;
        var registro = new BitacoraCambioRiesgoCliente(
            json.id,
            json.nivelRiesgoTipoCliente,
            json.nivelRiesgoActividadGeneradoraRecursos,
            json.nivelRiesgoCiudadania,
            json.nivelRiesgoPaisNacimiento,
            json.nivelRiesgoPaisActividadEconomica,
            json.nivelRiesgoTipoActivoPropio,
            json.nivelRiesgoProductos,
            json.nivelRiesgoCaracter,
            json.nivelRiesgoValorAsegurado,
            json.nivelRiesgoMedioPago,
            json.nivelRiesgoTipoIngresos,
            json.nivelRiesgo,
            json.fecha,
            json.EmpresaId,
            json.InformacionClienteId,
            json.ClienteId,
        );

        if(json.fecha) {
            registro.fecha = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'YYYY-MM-DD');
            registro.fechaFormato = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'DD/MM/YYYY');
            registro.fechaDate = this.convertirFecha(json.fecha, 'YYYY-MM-DD', 'date');
        }

        if(json.Cliente) {
            var cliente = this.usuarioDeJSON(json.Cliente);
            registro.cliente = cliente;
        }

        return registro;
    }

    public convertirFecha(fecha: any, formatoEntrada: string, formatoSalida: string) {
        try {
            var fecha;
            if(formatoEntrada === 'date') fecha = moment(fecha);
            else fecha = moment(fecha, formatoEntrada);

            var res;
            if(formatoSalida === 'date') res = fecha;
            else res = fecha.format(formatoSalida);
            return res;
        } catch(error) {
            return null;
        }
    }

    public convertirFechaATimezone(fecha: any, formatoSalida: string, timezone: string){
        var fechaConvertida = momentTimezone.tz(fecha, timezone);
        return fechaConvertida.format(formatoSalida);
    }

    indexOf(array, findKey, value) {
        if(array) {
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                if(element[findKey] == value) {
                    return i;
                }
            }
        }
        return -1;
    }

    public notificacionDeJSON = (json: any): Notificacion => {
        if(!json) return null;
        var registro: Notificacion;
        registro = new Notificacion(
            json.id,
            json.titulo,
            json.descripcion,
            json.tipo1,
            json.tipo2,
            json.tipo3,
            json.id1,
            json.id2,
            json.id3,
            json.fecha,
            json.mostrarPortal,
            json.enviarMail,
            json.EmpresaId,
            json.UsuarioId,
            json.isRead
        );
        if (json.fecha) {
            registro.fecha = this.convertirFechaATimezone(json.fecha, 'DD/MM/YYYY HH:mm', this.apiURLs.defaultTimezone);
        }
        return registro;
    }

    public bancoDeJSON = (json: any): Banco => {
        if(!json) return null;
        var registro = new Banco(
            json.id,
            json.nombre,
            json.EmpresaId,
        );

        if(json.Empresa) {
            var empresa = this.empresaDeJSON(json.Empresa);
            registro.Empresa = empresa;
        }

        return registro;
    }

    public tipoVehiculoDeJSON = (json: any): TipoVehiculo => {
        if(!json) return null;
        var registro = new TipoVehiculo(
            json.id,
            json.nombre,
            json.EmpresaId,
        );

        if(json.Empresa) {
            var empresa = this.empresaDeJSON(json.Empresa);
            registro.Empresa = empresa;
        }

        return registro;
    }
}
